/* eslint-disable */
var { rapidReady } = require('../../../../lib/utils/rapid');
var folderSelector = require('./fav-folderSelector.js')
var { getObjectValue } = require('../../../../lib/utils/obj')
var cookies = require('js-cookie')
var moment = require('moment')
var CryptoJS = require('crypto-js')
var LZString = require('lz-string')
var utils = require('./utils')

const add_modal = require('../../renderers/add_modal')
const add_folder_modal = require('../../renderers/add_folder_modal')
const edit_folder_modal = require('../../renderers/edit_folder_modal')
const delete_folder_modal = require('../../renderers/delete_folder_modal')
const save_favorites_modal = require('../../renderers/save_favorites_modal')

const add_favorites_modal = require('../../renderers/add_favorites_modal')
const edit_favorites_modal = require('../../renderers/edit_favorites_modal')
const delete_favorite_modal = require('../../renderers/delete_favorite_modal')
const save_folder_modal = require('../../renderers/save_folder_modal')

export default (() => {
  class favorites {
    constructor({ selector }) {
      const myElem = document.querySelector('.fav-listing-class')
      if (myElem) {
        this.config = JSON.parse(myElem.dataset.config)
        this.rapidConfig = JSON.parse(myElem.dataset.rapidConfig)
        this.init(this.config, selector)
      }
      this._initFavStorage()
      this._addEventListenersForOverlayGetStarted()
    }

    init(config, selector) {
      // Initial data load
      const _me = this
      const favsContext = this
      $.support.cors = true

      /* polyfill */
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
          return this.substr(position || 0, searchString.length) === searchString
        }
      }

      if (typeof $('.favorites-container') !== 'undefined' && $('.favorites-container').length) {
        this.nav = $(this.config.modules.sidenav)
        this.isAuth = $('.favorites-container').hasClass('auth-true')
        this.content = $('.favorites-container').find('.favorites-content')
        this.breadcrumb = $('.favorites-container').find('.favorites-breadcrumb')
        this.public_id = null
      }

      this._addEventListenersForListOfFolders()
      this._addEventListenerForNavButtons()
      this._updateFavoriteNumber()
      this._addSortEventListener()

      if (this.isAuth) {
        $('.favorites-empty-message.empty').addClass('hide')
        $('.favorites-empty-message.loading').removeClass('hide')
        _me.public_id = CryptoJS.SHA256().toString()
        _me._reloadFavorites(0)
        _me.legacy_init(config)
      }
    }

    _initFavStorage() {
      let favftuxLocalStorage = this.getLocalStorage('favftux')
      let fCookie = !!~document.cookie.indexOf('favftux')
      if (!favftuxLocalStorage || !fCookie) {
        document.cookie = 'favftux=true'
        this.setLocalStorage('favftux', true)
      }
    }

    _addEventListenersForOverlayGetStarted() {
      let overlay = document.querySelector('.favorites-overlay')
      if (overlay) {
        let getStartedButton = document.querySelector('.fav-ftux-getstart')
        getStartedButton.addEventListener('click', () => {
          window.location.reload()
        })
      }
    }

    _addEventListenersForListOfFolders() {
      const folders = document.querySelectorAll('.folder')

      folders.forEach(f =>
        f.addEventListener('click', () => {
          if (document.querySelector('.favorites-current'))
            document.querySelector('.favorites-current').classList.remove('favorites-current')
          f.classList.add('favorites-current')

          let folderId = f.getAttribute('id')
          this._displayListOfItemsForFolder(folderId)

          this._changeBookmarkDate()
          this._updateFavoriteNumber()
        })
      )
    }
    _displayListOfItemsForFolder(folderId) {
      let folderItemList = document.getElementById(`folder-id-${folderId}`)
      if (folderItemList) {
        if (document.querySelector('.folder-list-current'))
          document.querySelector('.folder-list-current').classList.remove('folder-list-current')
        folderItemList.classList.add('folder-list-current')
      }
    }

    _addEventListenerForNavButtons() {
      let navButtons = [
        document.querySelector('.favorites-nav-root'),
        document.querySelector('.favorites-nav-button')
      ]
      navButtons.forEach(navButton => {
        navButton.addEventListener('click', () => {
          let defaultFolder = document.getElementById('folder-id-fnone')
          if (!defaultFolder.classList.contains('folder-list-current')) {
            document.querySelector('.folder-list-current').classList.remove('folder-list-current')
            defaultFolder.classList.add('folder-list-current')
            let currentSelected = document.querySelector('.favorites-current')
            if (currentSelected) currentSelected.classList.remove('favorites-current')
          }
          this._changeBookmarkDate()
          this._updateFavoriteNumber()
        })
      })
    }

    _changeBookmarkDate(favList) {
      favList.forEach(item => {
        let currentDiv = document.getElementById(item.id)
        let itemDate = currentDiv.querySelector('.favorites-item-date')
        itemDate.textContent = this.getFormattedTime(item.modTime)
      })
    }

    _changeBookmarkDate() {
      let currentList = document.querySelector('.folder-list-current')
      let elementList = currentList.querySelectorAll('.favorites-item')
      elementList.forEach(element => {
        let dateDiv = element.querySelector('.favorites-item-date')
        if (dateDiv) {
          let itemDate = Number(dateDiv.getAttribute('id'))
          dateDiv.textContent = this.getFormattedTime(itemDate)
        }
      })
    }

    _updateFavoriteNumber() {
      let favoriteCountDiv = document.querySelector('.favorites-nav-count')
      let currentList = document.querySelector('.folder-list-current')
      if (currentList) {
        let favList = currentList.querySelectorAll('.favorites-item')
        let favCount = favList.length
        if (favCount == 1 && favList[0].classList.contains('favorites-empty')) favCount = 0
        favoriteCountDiv.textContent = `${favCount} ${favCount == 1 ? 'favorite' : 'favorites'}`
      }
      setTimeout(() => {
        rapidReady(rapid => {rapid.refreshModule()})
      }, 500)
    }

    sendCustomTracking({ sec, slk, ...opts }) {
      rapidReady(rapid => {rapid.beaconClick(sec, slk, 1, opts)})
    }

    _addSortEventListener() {
      let sortSelect = document.getElementsByName('favorites_sort')
      if (sortSelect.length > 0) {
        sortSelect[0].addEventListener('change', e => {
          const sortText = getObjectValue(e.target.selectedOptions, [0, 'innerText'])
          const { items, sort } = this.rapidConfig
          this._applySortToFolderItems()
          this.sendCustomTracking({ ...items, ...sort, slk: sortText })
        })
      }
    }
    _applySortToFolderItems() {
      let sortFunction = this.getSortFunctionElements()
      let currentFolder = document.querySelector('.folder-list-current')
      let currentFolderItems = currentFolder.querySelectorAll('.favorites-item')
      if (
        currentFolderItems.length > 0 &&
        !currentFolderItems[0].classList.contains('favorites-empty')
      ) {
        let itemsArray = Array.prototype.slice.call(currentFolderItems)
        itemsArray.sort(sortFunction)
        currentFolder.innerHTML = ''
        itemsArray.forEach((item, index) => {
          if (item.classList.contains('favorites-last')) item.classList.remove('favorites-last')
          if (index == itemsArray.length - 1) item.classList.add('favorites-last')
          currentFolder.appendChild(item)
        })
      }
    }

    _encrypt(items, encryptionKey) {
      var jsonStr = JSON.stringify(items)
      jsonStr = LZString.compressToUTF16(jsonStr)
      var encrypted = CryptoJS.AES.encrypt(jsonStr, encryptionKey).toString()
      return encrypted
    }

    _decrypt(encryptedText, encryptionKey) {
      var bytes = CryptoJS.AES.decrypt(encryptedText, encryptionKey)
      var decrypted = bytes.toString(CryptoJS.enc.Utf8)
      return LZString.decompressFromUTF16(decrypted)
    }

    _refreshUI(folderId) {
      var displayedItems = this.cachedFavoritesItems
      if (folderId != 0 && folderId != '0') {
        var folderObject = this.findItemById(displayedItems, folderId, [])
        var displayedParentFolders = null
        if (folderObject != null && folderObject.item != null) {
          displayedItems = folderObject.item.children
          displayedParentFolders = folderObject.itemPath
        }
      }
      this.displayItems(displayedItems, displayedParentFolders)
      this._highlightFavoritesFolder(folderId)
    }

    _cleanupFolderMap() {
      if (this.folderMap != null) {
        for (var k in Object.keys(this.folderMap)) {
          if (k != 0 && k != '_top') {
            delete this.folderMap[k]
          } else {
            this.folderMap[k].children = []
          }
        }
      }
    }

    _reloadFavorites(folderId, suppressUIRefresh, successCallback) {
      var _me = this

      function loadWithEtag(withEtag) {
        const myElem = document.querySelector('.fav-listing-class')
        const config = JSON.parse(myElem.dataset.config)

        $.ajax({
          url: '/api/v1/favorites',
          xhrFields: {
            withCredentials: true
          },
          data: {
            app_id: 'web',
            referer: window.location.href
          },
          type: 'GET',
          dataType: 'json',
          success: function (response, textStatus, jqXHR) {
            var items
            _me.encryptionKey = jqXHR.getResponseHeader('X-FAV-KEY')
            _me.etag = jqXHR.getResponseHeader('ETag')
            var setLocalStorage = false
            if (jqXHR.status == 200) {
              items = response.data
              _me._adapt(items)

              setLocalStorage = true
              setTimeout(function () {
                _me._setLocalStorageFavorites(items)
              }, 0)
            } else {
              try {
                items = _me._getLocalStorageFavorites(true)
              } catch (exception) {
                console.error(exception)
                $(window).trigger('favorites:loadWithoutEtag')
              }
            }

            _me.cachedFavoritesItems = items
            _me._cleanupFolderMap()
            _me.setupFolderMap(items, 0)

            if (!suppressUIRefresh) {
              _me._refreshUI(folderId)
            }
            rapidReady( rapid => { rapid.refreshModule()})
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.error(errorThrown)
          },
          beforeSend: function (xhr) {
            if (withEtag) {
              var etag = _me._getLocalStorageFavoritesEtag()
              if (etag != null) xhr.setRequestHeader('If-None-Match', etag)
            }
          }
        }).always(function () {
          $('.favorites-empty-message.empty').removeClass('hide')
          $('.favorites-empty-message.loading').addClass('hide')
        })
      }

      $(window).on('favorites:loadWithoutEtag', function () {
        loadWithEtag(false)
      })

      if (this.isAuth) {
        loadWithEtag(true)
      }
    }

    _adapt(items) {
      var _me = this
      if (items != null && items.length > 0) {
        items.forEach(function (item) {
          item.title = item.name
          item.modTime = item.updated_at
          if (item.type != null) {
            item.type = item.type == 'folder' ? 2 : item.type == 'item' ? 1 : item.type
          }
          if (item.children == null) {
            item.children = []
          }
          delete item['name']
          delete item['updated_at']

          _me._adapt(item.children)
        })
      }
    }

    setupFolderMap(items, parentId) {
      if (items == null) return
      var _me = this

      items.forEach(function (item) {
        item.parent = parentId
        _me.folderMap[item.id] = item
        if (parentId == 0 && item.type == 2) {
          _me.folderMap[0].children.push(item)
        }

        if (item.children != null && item.children.length > 0) {
          _me.setupFolderMap(item.children, item.id)
        }
      })
    }

    _deleteFromFolderMap(folderId, parentId) {
      if (folderId != null && parentId != null) {
        var folder = this.folderMap[folderId]
        if (folder != null) {
          delete this.folderMap[folderId]

          function deleteSubfolders(items, folderMap) {
            if (items != null) {
              items.forEach(function (item) {
                if (item.type == 2) {
                  delete folderMap[item.id]
                  deleteSubfolders(item.children, folderMap)
                }
              })
            }
          }

          deleteSubfolders(folder.children, this.folderMap)
          // delete from parent folder
          var parentFolder = this.folderMap[parentId]
          if (parentFolder.children != null) {
            for (var i = 0; i < parentFolder.children.length; ++i) {
              if (parentFolder.children[i].id == folderId) {
                parentFolder.children.splice(i, 1)
                break
              }
            }
          }
        }
      }
    }

    _deleteItemFromLocalStorage(itemId) {
      var favorites = this._getLocalStorageFavorites()
      if (favorites != null) {
        function deleteItemById(items, id) {
          if (items != null && id != null) {
            for (var i = 0; i < items.length; ++i) {
              if (items[i].id == itemId) {
                items.splice(i, 1)
                break
              } else {
                deleteItemById(items[i].children, id)
              }
            }
          }
        }

        deleteItemById(favorites, itemId)

        this._setLocalStorageFavorites(favorites)
      }
    }

    insertBookmark(parentId, item) {
      var favoritesItems = this._getLocalStorageFavorites()
      if (parentId == '0') {
        favoritesItems.push(item)
      } else {
        var parentFolderObject = this.findItemById(favoritesItems, parentId, [])
        if (parentFolderObject != null && parentFolderObject.item != null) {
          var parentFolder = parentFolderObject.item
          if (parentFolder.children == null || typeof parentFolder.children == 'undefined') {
            parentFolder.children = []
          }
          parentFolder.children.push(item)
        }
      }

      this._setLocalStorageFavorites(favoritesItems)
      this._cleanupFolderMap()
      this.setupFolderMap(favoritesItems, 0)
    }

    displayItems(assetsItems, parentFolders) {
      var _me = this
      if (assetsItems == null || typeof assetsItems == 'undefined') return

      var items = []
      var folders = []
      assetsItems.forEach(function (item, index) {
        if (item.type == 2) {
          folders.push(item)
        } else if (item.type == 1) {
          items.push(item)
        }
      })

      _me.displayFolders(parentFolders == null ? [] : parentFolders, true)
      _me.displayFolders(folders, false)
      _me.displayBookmarks(items)
      _me._changeBookmarkDate(items)
      _me._applySortToFolderItems()
    }

    _sortByTitle(x, y) {
      var _x = x.title.toLowerCase()
      var _y = y.title.toLowerCase()
      var r = _x.localeCompare(_y)
      return r
    }

    _sortByDate(x, y) {
      return y.modTime - x.modTime
    }

    _sortByUrl(x, y) {
      if (x.url && y.url) {
        var _x = x.url.toLowerCase()
        var _y = y.url.toLowerCase()
        var r = _x.localeCompare(_y)
        return r
      }
    }

    _sortByElementTitle(x, y) {
      var _x = x.querySelector('.favorite-title').innerText.toLowerCase()
      var _y = y.querySelector('.favorite-title').innerText.toLowerCase()
      var r = _x.localeCompare(_y)
      return r
    }

    _sortByElementTitle(x, y) {
      var _x = x.querySelector('.favorite-title').innerText.toLowerCase()
      var _y = y.querySelector('.favorite-title').innerText.toLowerCase()
      var r = _x.localeCompare(_y)
      return r
    }

    _sortByElementDate(x, y) {
      var _x = x.querySelector('.favorites-item-date').getAttribute('id')
      var _y = y.querySelector('.favorites-item-date').getAttribute('id')
      return _y - _x
    }
    _sortByElementUrl(x, y) {
      var _x = x
        .querySelector('a')
        .getAttribute('href')
        .toLowerCase()
      var _y = y
        .querySelector('a')
        .getAttribute('href')
        .toLowerCase()
      var r = _x.localeCompare(_y)
      return r
    }

    getSortFunction() {
      var sortFunc = this._sortByTitle
      var sort = document.querySelector('.favorites-sort select').value
      if (sort == 'title') {
        sortFunc = this._sortByTitle
      } else if (sort == 'date') {
        sortFunc = this._sortByDate
      } else if (sort == 'url') {
        sortFunc = this._sortByUrl
      }
      return sortFunc
    }

    getSortFunctionElements() {
      var sortFunc = this._sortByElementTitle
      var sort = document.querySelector('.favorites-sort select').value
      if (sort == 'title') {
        sortFunc = this._sortByElementTitle
      } else if (sort == 'date') {
        sortFunc = this._sortByElementDate
      } else if (sort == 'url') {
        sortFunc = this._sortByElementUrl
      }
      return sortFunc
    }

    displayBookmarks(bookmarkItems) {
      var _me = this
      var $contentPageNav = $('.folder-list-current')
      $contentPageNav.empty()
      bookmarkItems.forEach(function (bookmarkItem, index) {
        var sanitizedTitle = _me.sanitizeInput(bookmarkItem.title)
        var sanitizedUrl = _me.sanitizeURLInput(bookmarkItem.url)
        if (!bookmarkItem.modTime) bookmarkItem.modTime = new Date().getTime()
        var $ele = $(
          '<div class="favorites-item" id="b' +
          bookmarkItem.id +
          '" data-id="' +
          bookmarkItem.id +
          '"></div>'
        )
        var $a = $(
          '<a href="' +
          sanitizedUrl +
          '" data-ylk="sec:favorites;itc:1;elm:link;" name="om_fav_lnk" title=\'' +
          sanitizedTitle +
          '\' target="_blank" id="favitem-' +
          bookmarkItem.id +
          '"><div class="favorites-item-content"><h3 class="favorite-title"></h3><span class="favorites-item-domain">' +
          _me.getUrlDomain(sanitizedUrl) +
          '</span></div></a>'
        )
        $a.find('h3').text(sanitizedTitle)
        var $divTime = $(`<div class="favorites-item-date" id=${bookmarkItem.modTime}> </div>`)
        var $tool = $(
          '<div class="favorites-item-toolbar"><a name="edit" class="navicon-edit" data-ylk="sec:favorites;itc:1;elm:bkmk-edit;subsec:edit-favorite" title="Edit" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="-253.056 -244.987 512 512"><title>Edit favorite</title><path fill="#444" d="M-250.597 265.639l7.023-130.524L45.645-154.103 168.656-31.092l-289.157 289.158-130.096 7.573zm45.052-113.559l-3.897 72.242 71.896-4.162L113.683-31.07 45.645-99.11l-251.19 251.19zM182.659-45.013L59.955-167.719l62.872-62.874c13.8-13.8 36.297-13.799 50.095 0l72.633 72.632c13.798 13.799 13.779 36.274-.021 50.074l-62.875 62.874zM114.97-167.699l67.731 67.732 32.948-32.947-67.731-67.733-32.948 32.948z"/></svg></a><a name="delete" class="navicon-close" data-ylk="sec:favorites;itc:1;elm:bkmk-del;subsec:edit-favorite" title="Delete" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 14.85 14.85"><title>Remove favorite</title><path fill="#000" d="M14.85 1.414L13.436 0l-6.01 6.01L1.413 0 0 1.414l6.01 6.01L0 13.437l1.415 1.414 6.01-6.01 6.01 6.01 1.415-1.414-6.01-6.01"/></svg></a></div>'
        )
        $a.appendTo($ele)
        $divTime.appendTo($ele)
        $tool.appendTo($ele)

        if (index == bookmarkItems.length - 1) {
          $ele.addClass('favorites-last')
        }
        $ele.appendTo($contentPageNav)
      })
      _me._updateFavoriteNumber()

      if (bookmarkItems.length == 0) {
        _me.addFavoritesEmptyDiv($contentPageNav)
      }
    }

    addFavoritesEmptyDiv(emptyDiv) {
      let $favoritesEmpty = $(`<div class="favorites-item favorites-empty"><div class="favorites-item-content"><div class="favorites-empty-list"><div class="favorites-empty-message empty">

      This folder is empty.
      Add faves by clicking
      the "+" sign above.

    <div class="favorites-empty-message loading hide">Please wait. Loading...</div></div></div>`)
      $favoritesEmpty.appendTo(emptyDiv)
    }

    displayFolders(folderItems, reset) {
      var _me = this
      var $sidenav = $(_me.config.modules.sidenav)
      if (reset) $sidenav.empty()

      var html = ''
      folderItems.forEach(function (folderItem, index) {
        var a_html = document.createElement('a');
        a_html.name = "select"
        a_html.classList = "lnid-sec4_lnk" + index
        a_html.title = folderItem.title
        a_html.href = "#"
        a_html.setAttribute("data-id", folderItem.id)
        a_html.setAttribute("data-ylk", "itc:1;elm:link")
        a_html.innerText = folderItem.title

        var tool_html =
          '<div class="favorites-item-toolbar"><a name="edit" data-ylk="sec:favorites;itc:1;elm:btn;" class="favorites-edit-button navicon-edit lnid-sec5_lnk' +
          2 * index +
          '" title="Edit" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="-253.056 -244.987 512 512"><title>Edit folder</title><path fill="#E6248A" d="M-250.597 265.639l7.023-130.524L45.645-154.103 168.656-31.092l-289.157 289.158-130.096 7.573zm45.052-113.559l-3.897 72.242 71.896-4.162L113.683-31.07 45.645-99.11l-251.19 251.19zM182.659-45.013L59.955-167.719l62.872-62.874c13.8-13.8 36.297-13.799 50.095 0l72.633 72.632c13.798 13.799 13.779 36.274-.021 50.074l-62.875 62.874zM114.97-167.699l67.731 67.732 32.948-32.947-67.731-67.733-32.948 32.948z"/></svg></a><a name="add" data-ylk="sec:favorites;itc:1;elm:btn;" class="favorites-add-button navicon-add lnid-sec5_lnk' +
          (2 * index + 1) +
          '" title="Add" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="-251.05 -251.05 512 512"><title>Add folder</title><path fill="#E6248A" d="M260.932 56.654V-46.772H56.655V-251.05H-46.772v204.278H-251.05V56.654h204.278v204.278H56.655V56.654z"/></svg></a></div>'
        var ele_html =
          '<li class="folder" id="f' + folderItem.id + '">' + a_html.outerHTML + tool_html + '</li>'
        html = html + ele_html
      })

      $sidenav.html($sidenav.html() + html)
      this._addEventListenersForListOfFolders()
    }

    _highlightFavoritesFolder(itemId) {
      if (itemId != null && itemId.length) {
        $('#f' + itemId).addClass('favorites-current')
        this.currentFolderId = itemId
      }
    }

    findFolderAndHierarchy(folderId) {
      var favoritesItems = this._getLocalStorageFavorites()
      //var itemPath = [];
      var returnObject = this.findItemById(favoritesItems, folderId, [])
      return returnObject
    }

    addItemToCachedFavoritesItems(item) {
      if (item != null && item.parent != null && typeof item.parent != 'undefined') {
        if (item.parent != 0 && item.parent != '0') {
          var parentFolderObject = this.findItemById(this.cachedFavoritesItems, item.parent, [])
          if (parentFolderObject != null && parentFolderObject.item != null) {
            if (parentFolderObject.item.children == null) {
              parentFolderObject.item.children = []
            }
            parentFolderObject.item.children.push(item)
            var sortFunc = this.getSortFunction()
            if (sortFunc != null) {
              parentFolderObject.item.children.sort(sortFunc)
            }
          }
        } else {
          this.cachedFavoritesItems.push(item)
          this.cachedFavoritesItems.sort(this.getSortFunction())
        }
      }
    }

    findItemById(items, id, itemPath) {
      var returnObject = {
        itemPath: null,
        item: null,
        index: null
      }
      if (items != null && items.length > 0) {
        for (var i = 0; i < items.length; ++i) {
          var item = items[i]
          itemPath.push(item)

          if (item.id == id) {
            returnObject.item = item
            returnObject.itemPath = itemPath
            returnObject.index = i
            return returnObject
          } else {
            var subReturnedObject = this.findItemById(item.children, id, itemPath)
            if (subReturnedObject.item != null) {
              return subReturnedObject
            }
          }

          itemPath.pop()
        }
      }

      return returnObject
    }

    _getLocalStorageFavoritesEtag() {
      var key = 'favorites'
      if (this.public_id != null) {
        key = key + '.' + this.public_id
      }
      try {
        var encryptedData = JSON.parse(window.localStorage.getItem(key))
        if (encryptedData != null) {
          var decryptedData = this._decrypt(encryptedData.encrypted, this.encryptionKey)
          this.cachedFavoritesItems = JSON.parse(decryptedData)
        }
        return encryptedData == null ? null : encryptedData.etag
      } catch (exception) {
        console.error(exception)
        return null
      }
    }

    _getLocalStorageFavorites(expectException) {
      if (this.cachedFavoritesItems == null) {
        var key = 'favorites'
        if (this.public_id != null) {
          key = key + '.' + this.public_id
        }

        if (
          this.encryptionKey != null &&
          typeof window.localStorage != 'undefined' &&
          window.localStorage != null
        ) {
          try {
            var obj = JSON.parse(window.localStorage.getItem(key))
            if (obj != null) {
              var decrypted = this._decrypt(obj.encrypted, this.encryptionKey)
              this.cachedFavoritesItems = JSON.parse(decrypted)
            }
          } catch (exception) {
            console.error(exception)
            if (expectException) {
              throw exception
            }
          }
        }
      }
      return this.cachedFavoritesItems
    }

    _setLocalStorageFavorites(items) {
      var key = 'favorites'
      if (this.public_id != null) {
        key = key + '.' + this.public_id
      }

      if (this.encryptionKey != null) {
        var obj = {
          etag: this.etag,
          encrypted: this._encrypt(items, this.encryptionKey)
        }
        try {
          var str = JSON.stringify(obj)
          window.localStorage.setItem(key, str)
        } catch (exception) {
          console.error(exception)
        }
      } else {
        console.error('Null encryption key!')
      }
    }

    legacy_init(config) {
      const favsContext = this

      // provide for custom configuration via init()
      if (config && typeof config == 'object') {
        $.extend(this.config, config)
      }

      // You can use logger from components or extensions
      //   this.logger = sandbox.logger;

      if (typeof $('.favorites-container') !== 'undefined' && $('.favorites-container').length) {
        this.namespace = 'aol-favorites'
        this.selectorNamespace = 'aol-selector'
        this.timeout = 3000

        this.currentFolderId = null
        this.folderMap = null //{'00001':{id:'00001',title:'My Favorites'},'00002':{id:'00002',title:'News',children:[]},'00003':{id:'00003',title:'Politics'},'0004':{id:'00004',title:'technology'}};
        this.bookmarksTotal = 0
        this.strings = null
        this.currentPage = 1
        this.currentSort = ''
        this.count = 20
        this.debug = false
        this.showErrorAlertMSecs = 3000

        this.showConfirmAddbkName = 'show_savebk'
        this.showConfirmAddFdrName = 'show_savefdr'
        this.maxFolderNameLength = 100
        this.bookmarkWidthOffset = 65
        this.folderWidthOffset = 40
        this.modalDialogs = true
        this.snsAuthUrl = ''
        this.maxSubFolderDepth = 10

        this.isAuth = false
        this.localBookmarkData = null
        this.maxLocalBookmarks = 25
        this.addedFirstLocalBookmark = false
        this.signinLinkClicked = false
        this.unauthCookieName = 'favs_u'
        this.itemGuid = 0
        this.pasting = false
        this.currentTitle = ''
        this.keyStroke = false
        this.fdrpos = 1
        this.bkpos = 1
        this.iPadEditMode = false
        this.isiPad = utils.isiPad()
        this.dragdropEnable = false
        this.isClientFavorites = false
        this.enableRefresh = true

        this.isAuth = $('.favorites-container').hasClass('auth-true')

        this.content = $('.favorites-container').find('.favorites-content')
        this.nav = $('.favorites-container').find('.favorites-nav-folders ul')
        this.breadcrumb = $('.favorites-container').find('.favorites-breadcrumb')
        this.emptyBookmarksMessage = this.content.find('.favorites-item.favorites-empty')
        this.isClientFavorites =
          utils
            .findClass($('.favorites-container')[0], /isClientFavorites-.*/)
            .replace('isClientFavorites-', '') == 'true'
            ? true
            : false

        var maxttlength = utils
          .findClass($('.favorites-container')[0], /maxttlength-.*/)
          .replace('maxttlength-', '')
        if (!isNaN(maxttlength)) {
          this.maxFolderNameLength = maxttlength
        }

        this.dragdropEnable = utils
          .findClass($('.favorites-container')[0], /dragdropEnable-.*/)
          .replace('dragdropEnable-', '')

        var AOLFavorites = typeof AOLFavorites === 'undefined' ? {} : AOLFavorites

        // Get debug value from environment
        var debugVal = this.getConfigValue('debug', this.debug)
        debugVal = String(debugVal).toLowerCase()
        this.debug = debugVal === true || debugVal == 'true' || debugVal == '1' || debugVal == 'yes'

        this.initStrings()

        //add edit link for iPad
        if (this.isiPad) {
          $('.favorites-container').addClass('faviPad')
          $('.favorites-header-buttons').append(
            '<a class="favorites-header-button lnid-sec1_lnk2" id="favorites-editmode" title="' +
            this.config.strings.editicontooltip +
            '">Edit</a>'
          )
        }

        if (this.isClientFavorites == true) {
          $('#favoptout').click(function () {
            window.app.localStorage.setInteger('20-255-1494', '0')
            window.location = 'aol://favorites'
            setTimeout('window.close()', 500)
          })
          $('#favoptin').click(function () {
            window.app.localStorage.setInteger('20-255-1494', '1')
            fTips.fetch('featuretip-favorites-makedefault-confirm', false)
          })
        }

        this.initBookmarks()
        this.ftuxinit()
      }

      this.makeAccessibityCompat()

      if ($('#msg-overlay').is(':visible')) {
        $(document).bind('cbox_closed', function () {
          $('.context-tip').removeClass('hd')
          $('.context-tip-arrow').removeClass('hd')
          this.favTips()
        })
      } else {
        this.favTips()
      }

      this.applyBindings()
      this.setupAdRefresh()

      this.initialized = true
    }

    applyBindings() {
      // Add event listeners, for current and future elements
      $(document)
        .bind('dialog-canceled.' + this.namespace, $.proxy(this.onDialogCanceled, this))
        .bind('delete-item.' + this.namespace, $.proxy(this.onItemDelete, this))
        .bind('update-item.' + this.namespace, $.proxy(this.onItemEdit, this))
        .bind('add-item.' + this.namespace, $.proxy(this.onItemAdd, this))

      $('.favorites-container').on(
        'click',
        '.favorites-nav-folders li a[name="select"]',
        $.proxy(this.onFolderItemClicked, this)
      )
      $('.favorites-container').on(
        'click',
        '.favorites-item a[name="delete"]',
        $.proxy(this.onBookmarkDeleteClicked, this)
      )
      $('.favorites-container').on(
        'click',
        '.favorites-item a[name="edit"]',
        $.proxy(this.onBookmarkEditClicked, this)
      )
      $('.favorites-container').on(
        'click',
        '.favorites-nav-folders li a[name="delete"]',
        $.proxy(this.onFolderDeleteClicked, this)
      )
      $('.favorites-container').on(
        'click',
        '.favorites-nav-folders li a[name="edit"]',
        $.proxy(this.onFolderEditClicked, this)
      )
      $('.favorites-container').on(
        'click',
        '.favorites-nav-folders li a[name="add"]',
        $.proxy(this.onAddButtonClicked, this)
      )
      $('.favorites-container').on('click', '.fTipClose', $.proxy(this.toggleFTip, this))
      $('.favorites-container').on('click', '.fTipItemClose', $.proxy(this.toggleFTipItem, this))

      $('.favorites-container')
        .find('.favorites-nav-button')
        .bind('click', $.proxy(this.onBookmarksButtonClicked, this))
      $('.favorites-container')
        .find('.favorites-nav-root')
        .bind('click', $.proxy(this.onBookmarksButtonClicked, this))

      // Listen for pagination click events
      this.content.on(
        'click',
        '.favorites-page-nav li a',
        $.proxy(this.onPageNavButtonClicked, this)
      )
      this.content.on(
        'click',
        '.favorites-nav-showall-button',
        $.proxy(this.onPageNavShowAllButtonClicked, this)
      )
    }

    dragStartHandler(event) {
      var elm = $(event.target)
      var id = elm.attr('id')
      event.originalEvent.dataTransfer.setData(
        'text/html',
        '<a href="' +
        elm.attr('href') +
        '" class="lnk-' +
        id +
        '">' +
        elm.find('h3').text() +
        '</a>'
      )
      event.originalEvent.dataTransfer.setData('text/uri-list', id)
      event.originalEvent.dataTransfer.setData('text/plain', id)
    }

    dragFolderStartHandler(event) {
      var elm = $(event.target)
      var id = elm.parent('li').attr('id')
      event.originalEvent.dataTransfer.setData('text/html', id)
      event.originalEvent.dataTransfer.setData('text/uri-list', id)
      event.originalEvent.dataTransfer.setData('text/plain', id)
    }

    dropHandler(event) {
      event.originalEvent.preventDefault()
      var elm = $(event.target)
      var data = event.originalEvent.dataTransfer.getData('text/html')
      var isFolder = data.match(/^f/) ? true : false
      if (data != '') {
        if (isFolder == true) {
          var targetFolderId = '0'
          var elmFolderId = data.replace('f', '')
          if (!elm.is('.favorites-nav-root, .favorites-nav-button')) {
            targetFolderId = elm
              .closest('li')
              .attr('id')
              .replace('f', '')
          }
          if (targetFolderId != this.getParentId(elmFolderId) && targetFolderId != elmFolderId) {
            if (
              this.currentFolderId == 0 ||
              (this.currentFolderId > 0 && this.isValidFolderDrop(targetFolderId, elmFolderId))
            ) {
              this.onFolderDragged($('#' + data), targetFolderId)
            }
          }
        } else {
          //dropping a bookmark to a top level folder
          if (elm.is('.favorites-nav-root, .favorites-nav-button')) {
            if (data.indexOf('lnk-favitem-') >= 0) {
              if (this.currentFolderId != 0) {
                this.onBookmarkMoved(data, '0')
              }
            } else {
              this.onBookmarkAddDropped(event, data)
            }
          } else if (elm.closest("li[id=^'f']").length > 0) {
            //dropping to folder on the folders nav
            if (data.indexOf('lnk-favitem-') >= 0) {
              //move current bookmark to a new folder
              var newFolderId = elm
                .closest('li')
                .attr('id')
                .replace('f', '')
              if (newFolderId != this.currentFolderId) this.onBookmarkMoved(data, newFolderId)
            } else {
              //create new bookmark in the folder
              this.onBookmarkAddDropped(event, data, elm.closest('li').attr('id'))
            }
          } else {
            //create new bookmark
            if (data.indexOf('lnk-favitem-') < 0) {
              this.onBookmarkAddDropped(event, data)
            }
          }
        }
      }
    }

    dragOverHandler(event) {
      event.originalEvent.preventDefault()
    }

    isValidFolderDrop(targ, elm) {
      var targLi = $('#f' + targ)
      var elmLi = $('#f' + elm)
      //FE check if target is one of the elm children (invalid)
      if (elmLi.hasClass('pfdr') && targLi.is('.subfdr , .favorites-current')) {
        fTips.fetch('featuretip-favorites-invalid-drop', false)
        return false
      } else if (elmLi.hasClass('favorites-current') && targLi.is('.subfdr')) {
        fTips.fetch('featuretip-favorites-invalid-drop', false)
        return false
      } else {
        return true
      }
    }

    initStrings() {
      this.strings = AOLFavorites.strings || {}
    }

    ftuxinit() {
      $('div.favorites-container').on('FavFTUXOverlayInit', function (event) {
        /* this is triggered from favoritesftux.jsp
         * its the only way to know the ftux overlay is active
         * we want to hide the initial unauth notice in this case
         * because its left edge was poking out under the overlay */
        self.hideNotice()
      })
      $('#fav-ftux-getstart').on('click', function () {
        self.showInitialUnauthNotice()
        $.fn.colorbox.close()
        self.track()
      })
      this.showInitialUnauthNotice()
    }

    showInitialUnauthNotice() {
      if (!this.isAuth) {
        var signInMessage = this.config.strings.noticeUnauthInitial
        if (signInMessage) {
          signInMessage.replace('_SIGNIN_', this.signInLink)
          this.showNotice(signInMessage, false)
        }
      }
    }

    /**
     * Init the bookmarks page
     */
    initBookmarks() {
      // Detect sort change, and update display of custom sort field
      $('.favorites-container')
        .find('.favorites-sort select')
        .change($.proxy(this.onSortChanged, this))
      $('.favorites-container')
        .find('.favorites-sort-button')
        .text(
          $('.favorites-container')
            .find('.favorites-sort select option:selected')
            .text()
        )
      $('.favorites-container').on(
        'click',
        '.favorites-tools-addfdr',
        $.proxy(this.onFolderAddClicked, this)
      )
      $('.favorites-container').on(
        'click',
        '.favorites-tools-addfav',
        $.proxy(this.onBookmarkAddClicked, this)
      )

      if (this.dragdropEnable == 'true' && this.isClientFavorites) {
        $('.favorites-container').on(
          'dragstart',
          '.favorites-item a[id=^"favitem-"]',
          $.proxy(function (event) {
            self.dragStartHandler(event)
          })
        )
        $('.favorites-container').on(
          'dragstart',
          '.favorites-nav-folders li a[name="select"], .favorites-current a[draggable="true"]',
          $.proxy(function (event) {
            self.dragFolderStartHandler(event)
          })
        )
        $('.favorites-container').on(
          'drop',
          '.favorites-nav-folders li , .favorites-content, .favorites-nav-root, .favorites-nav-button',
          $.proxy(function (event) {
            self.dropHandler(event)
          })
        )
        $('.favorites-container').on(
          'dragover',
          '.favorites-nav-folders li, .favorites-content, .favorites-nav-root, .favorites-nav-button',
          $.proxy(function (event) {
            self.dragOverHandler(event)
          })
        )
        //disable drop on non droppable area
        $('body')
          .children()
          .not(
            '.favorites-nav-folders li, .favorites-content, .favorites-nav-root, .favorites-nav-button'
          )
          .bind(
            'drop',
            $.proxy(function (event) {
              return false
            })
          )
      }
      this.bindTab()

      if (this.isiPad) {
        $('.favorites-container').on(
          'click',
          '#favorites-editmode',
          $.proxy(this.onEditModeClick, this)
        )
      }

      $(document).bind(
        'favorites-reload',
        $.proxy(function (event) {
          this._reloadFavorites(0, true)
        }, this)
      )

      $(document).bind('total-updated.' + self.namespace, $.proxy(this.onTotalUpdated, this))

      // Detect when new folder is added by selector plugin
      $(document).bind(
        'item-added.' + self.selectorNamespace,
        $.proxy(function (event) {
          var data = event.newData
          if (data.type == 2) {
            // Make sure folderMap is updated
            this.setFolderItem(data)

            if (data.parent == this.currentFolderId) {
              // For now refresh the nav when new folder is detected from the selector
              this.selectFolder(this.currentFolderId, this.currentPage, null, null, true, data)
            }
          }
        }, this)
      )

      $(document).bind(
        'folder-added.' + this.selectorNamespace,
        $.proxy(function (event) {
          var itemData = event.itemData
          if (itemData.type == 2) {
            if (itemData.parent == 0) {
              this.cachedFavoritesItems.push(itemData)
              this.cachedFavoritesItems.sort(this._sortByTitle)
            } else {
              var parentFolder = this.findItemById(this.cachedFavoritesItems, itemData.parent, [])
              if (parentFolder.item != null) {
                parentFolder.item.children.push(itemData)
                parentFolder.item.children.sort(this._sortByTitle)
              }
            }

            this.setupFolderMap(this.cachedFavoritesItems, 0)

            if (itemData.parent == this.currentFolderId) {
              this._refreshUI(this.currentFolderId)
            }
          }
        }, this)
      )

      // Detect when folderMap is updated by selector plugin
      $(document).bind(
        'folder-data-updated.' + self.selectorNamespace,
        $.proxy(function (event) {
          var map = event.folderMap
          if (map) {
            $.extend(this.folderMap, map)
          }
        }, this)
      )

      // When mouseenter/leave nav items, dynamically adjust truncation so there's no overlap
      this.nav.on(
        'mouseenter mouseleave',
        'li',
        $.proxy(function (event) {
          if (this.iPadEditMode) return
          var el = $(event.target).closest('li')
          var titleEl = el.children().first()

          var width = el.width()
          if (event.type == 'mouseenter') {
            width -= this.folderWidthOffset
            el.find('.favorites-item-toolbar').show()
          } else {
            el.find('.favorites-item-toolbar').hide()
            titleEl.text(titleEl.attr('title'))
          }

          this.truncateElementText(titleEl, width)
        }, this)
      )

      // Setup unauth behavior for leaving or clicking signin links
      if (!this.isAuth) {
        // Add signin class to main header signin link
        $('.sns a').addClass('favorites-signin-link')

        // Listen for any clicks to signin links
        $(document).on(
          'click',
          '.favorites-signin-link',
          $.proxy(function () {
            this.signinLinkClicked = true
          }, this)
        )

        // Show user sign-in reminder if unauth user leaves page; but not if clicked signin link
        window.onbeforeunload = $.proxy(this.onLeavePageUnauth, this)
      }

      // Initialize config and bookmark data
      this.count = parseInt(AOLFavorites.count) || 20
      this.maxLocalBookmarks = parseInt(AOLFavorites.maxLocalBookmarks) || this.count || 25
      this.maxSubFolderDepth = parseInt(AOLFavorites.maxSubFolderDepth) || 10

      this.snsAuthUrl = AOLFavorites.snsAuthUrl || ''
      this.signInLink =
        '<a name="om_favorites_signin" class="favorites-signin-link" href="' +
        this.snsAuthUrl +
        '">Sign in</a>'

      this.folderMap = {}
      if (AOLFavorites.folders) {
        AOLFavorites.folders.parentId =
          AOLFavorites.folders.parentId >= 0 ? AOLFavorites.folders.parentId : 0
      }
      var initialData = this.isAuth
        ? AOLFavorites.folders
        : {
          totalBookmarks: 0,
          parentId: 0,
          assets: []
        }
      this.updateFolderData(initialData)
      if (!this.isAuth) {
        this.localBookmarkData = []

        // Delete local unauth bookmark cookie
        if (cookies) {
          cookies.set(this.unauthCookieName, '', new Date('1970').toGMTString())
        }
      }
    }

    // Initialize and setup the DOM
    // this.initContent()
    // this.updateNav()
    // this.updateCrumbNav()

    /**
     * Init the bookmarks content block, used each time content is refreshed
     */
    initContent() {
      var favs = this

      this.content.find('.favorites-item').each(function (i, element) {
        var $el = $(element)
        var titleEl = $el.find('h3')

        // Truncate title if too wide
        favs.truncateElementText(titleEl, $el.width() - self.bookmarkWidthOffset)
      })

      $('.favorites-container')
        .find('.favorites-item-date')
        .each(function () {
          var ts = Number($(this).html())
          $(this).html(favs.getFormattedTime(ts))
        })
      $('.favorites-item-date').css('visibility', 'visible')
    }

    /**
     * Utility methods
     */

    /**
     * Extract and return config value first from page, then overriden by URL param
     */
    getConfigValue(name, defaultVal) {
      var value = null

      if (AOLFavorites.hasOwnProperty(name)) {
        value = AOLFavorites[name]
      }
      var match = window.location.search.match(new RegExp('\\b' + name + '=([^\\?&#;]+)\\b', 'i'))
      if (match) {
        value = decodeURIComponent(match[1])
      }
      if (value == null && defaultVal != null) {
        value = defaultVal
      }
      value = $.trim(value)

      return value
    }

    getString(name) {
      return this.config.strings[name] || ''
    }

    getItemType(type) {
      return type == 1 ? 'bookmark' : type == 2 ? 'folder' : type == 3 ? 'both' : 'unknown'
    }

    getUrlDomain(url) {
      if (url.startsWith('aol')) {
        return url
      } else {
        var domain = url.match(/^(?:\w+:\/+)?([^\/:]+)/i)[1]
        domain = domain.replace(/^www\./i, '')
        return domain
      }
    }

    getFormattedTime(t) {
      t = t + new Date().getTimezoneOffset() || new Date().getTime()
      var formattedTime = ''
      var current = new Date(t)
      var dformat = this.config.strings.dformat == '' ? 'M/d/yy' : this.config.strings.dformat
      var tformat = this.config.strings.tformat == '' ? 'h:mm a' : this.config.strings.tformat
      var today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      if (t >= today.getTime()) {
        var showAmPm = false
        if (tformat.indexOf('a') != -1) {
          tformat = tformat.replace('a', '')
          showAmPm = true
        }
        var hours = current.getHours()
        var am = 'am'
        var pm = 'pm'
        var amPm = hours < 12 ? am : pm
        hours = hours % 12
        hours = hours == 0 ? 12 : hours
        formattedTime = moment(t).format(tformat)
        if (showAmPm) {
          formattedTime += ' ' + amPm
        }
      } else {
        formattedTime = moment(t).format(dformat)
      }
      return formattedTime
    }

    pad(str, max, character) {
      str = String(str)
      character = String(character == null ? '0' : character)
      return str.length < max ? this.pad(character + str, max) : str
    }

    cloneObject(obj) {
      return $.parseJSON(JSON.stringify(obj))
    }

    truncateElementText(textEl, maxWidth) {
      textEl = $(textEl)
      if (textEl && textEl.length && maxWidth > 0) {
        var text = textEl.text()
        if (text != '') {
          var width = textEl.width()

          if (width >= maxWidth) {
            var suffix = '...'

            while (width >= maxWidth && text != '') {
              text = text.slice(0, -1)
              textEl.text(text + suffix)
              width = textEl.width()
            }

            if (text == '') {
              textEl.text('...')
            }
          }
        }
      }
    }

    validateInput(text) {
      var isValid = true
      if (text == null || text == '') {
        isValid = false
      }
      return isValid
    }

    validateURLInput(text) {
      var isValid = this.validateInput(text)
      if (isValid) {
        isValid =
          Boolean(
            text.match(
              /^((http|https|aol)+\:\/\/)?(\S*@)?((([^\!@#$%^&*()_=+\[\]{}\|;:'",.<>/?]+\.)+[^\!@#$%^&*()_=+\[\]{}\|;:'",.<>/?]+)|localhost|[0-9]+)(\:\w*)?([/?#])?(\S*[^<>])$/i
            )
          ) || Boolean(text.match(/^aol:\/\/*/))
        if (self.isClientFavorites) {
          isValid = Boolean(isValid || text.match(/^aol:\/\/*/))
        }
      }
      return isValid
    }

    htmlEncode(string) {
      return $('<div/>')
        .text(string)
        .html()
    }

    sanitizeInput(text) {
      if (text && text != '') {
        // Substitute common HTML eneities with characters
        text = text.replace('&amp;', '&')
        text = text.replace('&apos;', "'")
        text = text.replace('&quot;', '"')
        text = text.replace('&lt;', '<')
        text = text.replace('&gt;', '>')
        text = text.replace('&nbsp;', ' ')

        text = text.replace(/\&\#?[\w]+;/gi, '') // Strip all other HTML entities

        text = text.replace(/<[^>]+>/gi, '') // Strip HTML tags
        text = text.replace(/['"]+/g, '') // Strip quotes
        // Trim and collapse whitespace
        //text = $.trim(text);
        text = text.replace(/\s+/g, ' ')
      }
      return text
    }

    sanitizeURLInput(text) {
      if (text && text != '') {
        text = this.sanitizeInput(text)
        text = $.trim(text)
        if (text.indexOf('://') == -1) {
          text = 'http://' + text
        }
      }
      return text
    }

    /*
     * Folder API methods
     */

    getParentId(id) {
      var id = this.getFolderItem(id) ? this.getFolderItem(id).parent : 0
      return id == null ? null : id
    }

    getTopId() {
      return this.getFolderItem('_top') ? this.getFolderItem('_top').id : 0
    }

    updateFolderData(newData) {
      var favs = this
      if (newData) {
        this.updateBookmarksTotal(newData.totalBookmarks)

        this.currentFolderId = newData.parentId

        // Set up folder structure
        var folderData = $.extend(this.getFolderItem(newData.parentId), {
          id: newData.parentId,
          children: newData.assets,
          type: 2
        })

        // Process folderData into flat list of ids
        function process(data, parent) {
          if (data.type != 2) return
          if (!data.hasOwnProperty('parent')) {
            data.parent = parent
          } else {
            parent = data.parent
          }

          if (data.children && data.children.length) {
            $.each(data.children, function update(i, obj) {
              process(obj, data.id)
            })
          }
          favs.setFolderItem(data)
        }
        process(folderData)
      }
    }

    _favoritesSort(x, y) {
      if (x.type == y.type) {
        var _x = x.title.toLowerCase()
        var _y = y.title.toLowerCase()
        var r = _x.localeCompare(_y)
        return r
      } else {
        return y.type - x.type
      }
    }

    getFolderItem(id) {
      return this.folderMap[id] || null
    }

    setFolderItem(data) {
      if (data && data.id != null) {
        this.folderMap[data.id] = data

        if (typeof data.parent != 'undefined') {
          var pId = data.parent == 0 || data.parent == '0' ? 0 : data.parent
          if (this.folderMap[pId].children == null) {
            this.folderMap[pId].children = []
          }
          this.folderMap[pId].children.push(data)
          this.folderMap[pId].children.sort(this._favoritesSort)
        }

        if (data.parent == 0 || data.parent == '0') {
          // folder map handling

          var o = this.findItemById(this.cachedFavoritesItems, data.parent, [])
          if (o != null && o.item != null) {
            if (o.item.children == null) {
              o.item.children = []
            }

            o.item.children.push(data)
          }
        } else {
        }

        // If no parent, is top folder
        if (data.id == 0) {
          data.title = this.config.strings.rootFolderName || 'MY FAVES'
          this.folderMap['_top'] = data
        } else {
          // Add folder to children array of parent
          if (data.parent == null || data.parent == 0 || data.parent == '0') {
            var updatedItems = this._getLocalStorageFavorites()
            updatedItems.push(data)
            updatedItems = updatedItems.sort(this._favoritesSort)
            this._setLocalStorageFavorites(updatedItems)
            this.folderMap[data.id] = data
          } else {
            function insertItem(items, _d, sortFunc) {
              if (items) {
                var pos = -1
                for (var i in items) {
                  if (items[i].id == _d.id) {
                    pos = i
                    break
                  }
                }
                if (pos == -1) {
                  items.unshift(_d)
                  items.sort(sortFunc)
                } else {
                  items[pos] = _d
                }
                return items
              } else {
                return [_d]
              }
            }
            var parent = this.getFolderItem(data.parent)
            if (parent) {
              insertItem(parent.children, data, this._favoritesSort)
            }
            var localStoredFavoritesItems = this._getLocalStorageFavorites()
            var parentFolderObject = this.findItemById(localStoredFavoritesItems, data.parent, [])
            if (parentFolderObject != null && parentFolderObject.item != null) {
              if (parentFolderObject.item.children == null) {
                parentFolderObject.item.children = []
              }

              insertItem(parentFolderObject.item.children, data, this._favoritesSort)
              this._setLocalStorageFavorites(localStoredFavoritesItems)
            }
          }
        }
      }
    }

    removeFolderItem(data) {
      if (data && this.folderMap[data.id]) {
        delete this.folderMap[data.id]

        // Remove folder from parent's children array'
        if (data.parent != null) {
          var parent = this.getFolderItem(data.parent)
          if (parent) {
            for (var i in parent.children) {
              if (parent.children[i].id == data.id) {
                parent.children.splice(i, 1)
                break
              }
            }
          }
        }
      }
    }

    updateBookmarksTotal(total) {
      if (this.bookmarksTotal != total) {
        this.bookmarksTotal = total
        $('.favorites-container').trigger({
          type: 'total-updated.' + this.namespace,
          total: this.bookmarksTotal
        })
      }
    }

    buildDataFromLink(lnkdata, pId) {
      var s = $($(lnkdata)).filter('a')
      var href = s.attr('href')
      var text = s.text()
      var favId = ''
      var newpId = pId != null ? pId : this.currentFolderId
      if (s.is("[class^='lnk-favitem-']")) {
        favId = s.attr('class').replace('lnk-favitem-', '')
      }

      var data = {
        type: 1,
        parent: newpId,
        title: text,
        url: href
      }
      if (favId != '') {
        $.extend(data, {
          id: favId
        })
      }
      return data
    }

    getDataFromBookmark(element) {
      var data = {
        type: 1,
        parent: this.currentFolderId
      }
      if (element && element.length) {
        $.extend(data, {
          id: element.attr('id').substr(1),
          title: element.find('a:first').attr('title'),
          url: element.find('a:first').attr('href')
        })
      }
      return data
    }

    getDataFromFolder(element, forceParentId) {
      var dataParent = this.currentFolderId
      if (forceParentId != null) dataParent = forceParentId
      var data = {
        type: 2,
        parent: dataParent
      }
      if (element && element.length) {
        var id = element.attr('id').substr(1)

        $.extend(data, this.getFolderItem(id), {
          title: element
            .children()
            .first()
            .attr('title')
        })
      }
      return data
    }

    getBookmarkItem(id) {
      var item = null
      if (this.localBookmarkData) {
        var i = this.getBookmarkItemIndex(id)
        if (i > -1) {
          item = this.localBookmarkData[i]
        }
      }
      return item || null
    }

    getBookmarkItemIndex(id) {
      var index = -1
      if (this.localBookmarkData) {
        for (var i in this.localBookmarkData) {
          var item = this.localBookmarkData[i]
          if (item.id == id) {
            index = i
            break
          }
        }
      }
      return index
    }

    setBookmarkItem(data) {
      if (data && data.id != null && this.localBookmarkData) {
        var i = this.getBookmarkItemIndex(data.id)
        if (i > -1) {
          this.localBookmarkData[i] = data
        } else {
          this.localBookmarkData.unshift(data)
        }

        if (cookies) {
          cookies.set(
            this.unauthCookieName,
            encodeURIComponent(JSON.stringify(this.localBookmarkData)),
            'session'
          )
        }
      }
    }

    removeBookmarkItem(data) {
      if (data && data.id != null && this.localBookmarkData) {
        var i = this.getBookmarkItemIndex(data.id)
        if (i > -1) {
          this.localBookmarkData.splice(i, 1)

          if (cookies) {
            cookies.set(
              this.unauthCookieName,
              encodeURIComponent(JSON.stringify(this.localBookmarkData)),
              'session'
            )
          }
        }
      }
    }

    getBookmarkTotal() {
      var total = 0
      if (this.localBookmarkData) {
        total = this.localBookmarkData.length
      } else {
        total = this.bookmarksTotal
      }
      return total
    }
    /**
     * Return URI encoded path to this folder, for use as query param
     */
    getFolderPath(id, altTitle) {
      var path = ''
      var self = this

      function getPath(curId, path) {
        path = path || ''
        var obj = self.getFolderItem(curId)
        if (obj && obj.parent != null) {
          var title = curId == id && altTitle ? altTitle : obj.title
          title = title.replace(/\//g, '//')
          path = '/' + encodeURIComponent(title || '') + path
          path = getPath(obj.parent, path)
        }
        return path
      }

      return getPath(id) || '/'
    }

    getFolderLevel(id) {
      var level = 0
      var favs = this

      function getLevel(id) {
        var obj = favs.getFolderItem(id)
        if (obj && obj.parent != null) {
          level++
          getLevel(obj.parent)
        }
      }
      getLevel(id)

      return level
    }
    /*
     * Event handlers
     */

    onSortChanged(event) {
      var sortEl = $(event.target)
      this.currentSort = sortEl.val() || ''

      $('.favorites-container')
        .find('.favorites-sort-button')
        .text(sortEl.find('option:selected').text())

      // this._reloadFavorites(this.currentFolderId)
      // this.selectFolder(this.currentFolderId, 1, null, this.currentSort, true)
      //omn.omcl("favorites-sort-"+this.currentSort);
      // this.sorttracking($('.favorites-container')[0], this.currentSort)
      // this.refreshIndividualAds()
      // this.track()
      // this.bindTab()
    }

    sorttracking(el, sorttype) {
      try {
        if (typeof bN != 'undefined') {
          bN.extractIds(el)
          bN.set('mnid', 'favorites-sort-' + sorttype, 1)
          bN.ping('favorites')
        }
      } catch (e) { }
    }

    track() {
      // var arr = bN_cfg.p
      // $.each(arr, function(i, v) {
      //   var varr = v[0]
      //   if (varr == 'plids') {
      //     try {
      //       if (typeof bN != 'undefined') {
      //         bN.set('plids', v[1], 1)
      //         bN.view()
      //       }
      //     } catch (e) {}
      //   }
      // })
    }

    /**
     * Select the top level folder when 'Bookmarks' button is clicked
     */
    onBookmarksButtonClicked(event) {
      if (this.currentFolderId != 0) {
        //this.selectFolder(this.getTopId(), 1);
        this.currentFolderId = 0
        this.displayItems(this._getLocalStorageFavorites())

        //omn.omcl("favfdr-root");
        this.refreshIndividualAds()
        this.track()
      }
    }

    /**
     * Select the current folder when nav or breadcrumb item is clicked
     */
    onFolderItemClicked(event) {
      var itemId = $(event.target).data('id')
      var selectedObject = this.findFolderAndHierarchy(itemId)

      if (selectedObject != null && selectedObject.item != null) {
        this.displayItems(selectedObject.item.children || [], selectedObject.itemPath)
        this._highlightFavoritesFolder(itemId)
        this.currentFolderId = itemId
      }

      this.refreshIndividualAds()
      this.track()
    }

    onAddButtonClicked(event) {
      var elm = $(event.target)
      var newparent = elm
        .closest('li')
        .attr('id')
        .substr(1)
      var data = {
        type: 3,
        parent: newparent
      }
      this.showDialogByAction('add', data)
      //omn.omcl("add-dialog-open");
    }

    onBookmarkAddDropped(event, lnkdata, newParentId) {
      var elm
      var newparent = null
      if (lnkdata != undefined) {
        if (newParentId != undefined) {
          elm = $('#' + newParentId).find('a.favorites-add-button')
          newparent = newParentId.replace('f', '')
        } else {
          elm = $('.fav-addtool .navicon-add')
        }
      }
      if (elm.hasClass('fnp') || elm.attr('class') == undefined) {
        newparent = utils.findClass($('.fnp')[0], /f-.*/).replace('f-', '')
      }
      if (this.isAuth || this.getBookmarkTotal() < this.maxLocalBookmarks) {
        var data = self.buildDataFromLink(lnkdata, null)
        if (newparent != null || data.newparent != null) {
          $.extend(data, {
            newparentId: newparent
          })
        }

        this.showDialogByAction('addbk', data)
        if (this.isAuth) this.hideNotice(true)
      } else {
        this.hideAllDialogs()
        var signInMessage = this.config.strings.noticeUnauthMaxLimitReached.replace(
          '_SIGNIN_',
          this.signInLink
        )
        this.showNotice(signInMessage)
      }
      //omn.omcl("addbk-dialog-open");
    }

    onBookmarkAddClicked(event) {
      var elm = $(event.target)
        .parents('div:first')
        .find('a:first')
      var newparent = null
      if (elm.hasClass('fnp') || elm.attr('class') == undefined) {
        newparent = utils.findClass($('.fnp')[0], /f-.*/).replace('f-', '')
      }
      if (this.isAuth) {
        var data = this.getDataFromBookmark(null)
        if (newparent != null || data.newparent != null) {
          $.extend(data, {
            newparentId: newparent
          })
        }
        this.showDialogByAction('addbk', data)
        if (this.isAuth) this.hideNotice(true)
      } else {
        if (this.getBookmarkTotal() < this.maxLocalBookmarks) {
          this.toggleFTipItem()
        } else {
          this.hideAllDialogs()
          var signInMessage = this.config.strings.noticeUnauthMaxLimitReached.replace(
            '_SIGNIN_',
            this.signInLink
          )
          this.showNotice(signInMessage)
        }
      }

      //omn.omcl("addbk-dialog-open");
    }

    onBookmarkDeleteClicked(event) {
      var element = $(event.target).closest('.favorites-item')
      var data = this.getDataFromBookmark(element)

      this.showDialogByAction('delbk', data)
      //omn.omcl("delbk-dialog-open");
    }

    onBookmarkMoved(lnkdata, newParentId) {
      var data = self.buildDataFromLink(lnkdata, newParentId)

      this.showDialogByAction('edtbk', data)
      //omn.omcl("edtbk-dialog-open");
    }

    onBookmarkEditClicked(event) {
      var element = $(event.target).closest('.favorites-item')
      var data = this.getDataFromBookmark(element)

      this.showDialogByAction('edtbk', data)
      //omn.omcl("edtbk-dialog-open");
    }

    onFolderAddClicked(event) {
      if (this.isAuth) {
        var elm = $(event.target)
          .parents('div:first')
          .find('a:first')
        var newparent = null
        if (elm.hasClass('fnp') || elm.attr('class') == undefined) {
          newparent = utils.findClass($('.fnp')[0], /f-.*/).replace('f-', '')
        }
        var data = this.getDataFromFolder(null, newparent)
        if (elm.closest('a').hasClass('auth-false')) {
          //elm.closest("div.addicon").find(".unauth-error").show();
        } else {
          this.showDialogByAction('addfdr', data)
        }
        //omn.omcl("addfdr-dialog-open");
      } else {
        this.toggleFTip()
      }
    }

    onFolderDeleteClicked(event) {
      var element = $(event.target).closest('li')
      var data = this.getDataFromFolder(element, null)

      this.showDialogByAction('delfdr', data)
      //omn.omcl("delfdr-dialog-open");
    }

    onFolderEditClicked(event) {
      var element = $(event.target).closest('li')
      var data = this.getDataFromFolder(element, null)

      this.showDialogByAction('edtfdr', data)
      //omn.omcl("edtfdr-dialog-open");
    }

    onFolderDragged(elm, targetFolderId) {
      var data = this.getDataFromFolder(elm, targetFolderId)
      $.extend(data, {
        parent: targetFolderId
      })

      this.showDialogByAction('edtfdr', data)
      //omn.omcl("edtfdr-dialog-open");
    }

    onDialogCanceled(event) {
      this.hideDialog($(event.target))
    }

    onPageNavButtonClicked(event) {
      var link = $(event.target)
      var element = link.closest('li')
      var num = 0

      if (element.hasClass('favorites-previous')) {
        num = this.currentPage > 1 ? this.currentPage - 1 : 1
        //omn.omcl("favorites-prev-page"+num);
      } else if (element.hasClass('favorites-next')) {
        num = this.currentPage + 1
        //omn.omcl("favorites-next-page"+num);
      } else {
        num = parseInt(link.text())
        //omn.omcl("favorites-page-"+num);
      }
      this.selectFolder(this.currentFolderId, num, null, null, true)
      this.refreshIndividualAds()
      this.track()
      this.bindTab()
    }

    onPageNavShowAllButtonClicked(event) {
      this.selectFolder(this.currentFolderId, 1, -1, null, true) // start=-1 means showall
      //omn.omcl("favorites-showall");
      this.refreshIndividualAds()
      this.track()
      this.bindTab()
    }

    onLeavePageUnauth(event) {
      if (this.getBookmarkTotal() > 0 && !this.signinLinkClicked) {
        return this.config.strings.noticeUnauthExit
      } else {
        this.signinLinkClicked = false
      }
    }

    syncClient() {
      ignoreClientSync = true
      window.app.favorites.requestSync()
      setTimeout(function () {
        ignoreClientSync = false
      }, 3000)
    }
    /*
     * Data request methods
     */

    onItemDelete(event) {
      var data = event.itemData
      var favs = this

      var dialog = $(event.target)

      if (this.isAuth) {
        var onRequestComplete = function onRequestComplete(response, textStatus, jqXHR) {
          if (jqXHR.status == 200 || jqXHR.status == 204) {
            if (data.type == 1) {
              favs.removeBookmark(data.id, data.parent)
            } else {
              //favs.removeFolderItem(data);
              favs.removeFolder(data.id, data.parent)
            }
            favs.hideDialog(dialog)
            if (favs.isClientFavorites == true) {
              favs.syncClient()
            }
          } else {
            favs.showErrorAlert(favs.config.strings.errorUnknown)
          }

          if (event.originalDialog != null)
            setTimeout(function () {
              event.originalDialog.deleting = 0
            }, 200)
        }

        // If deleting current folder, use it's parent's path
        var path = null
        if (data.type == 2) {
          path = this.getFolderPath(this.getParentId(data.id))
        }

        var windowReferer = 'https://www.aol.com/favorites/'
        $.ajax({
          url: '/api/v1/favorites/' + data.id + '?app_id=web&referer=' + windowReferer,
          type: 'DELETE',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          timeout: 3000
        })
          .done(onRequestComplete)
          .fail(function (jqXHR, textStatus, errObj) {
            favs.showErrorAlert(favs.config.strings.errorUnknown)
            if (event.originalDialog != null) event.originalDialog.deleting = 0
          })
      } else {
        this.deleteItemLocal(data, dialog)
      }
    }

    onItemEdit(event) {
      var _me = this
      const favsContext = this

      var itemData = event.itemData
      var data = event.newData
      var favs = this
      var dialog = $(event.target)
      if (!favs.onTitleEdit()) return

      if (this.isAuth) {
        var onRequestComplete = function onRequestComplete(response, textStatus, jqXHR) {
          if (jqXHR.status == 200 || jqXHR.status == 204) {
            // update local data in memory
            if (jqXHR.status == 200 && response.name != null) {
              data.title = response.name
            }

            var editedItem = favs.findItemById(favs.cachedFavoritesItems, data.id, [])
            if (editedItem != null && editedItem.item != null) {
              editedItem.item.title = data.title
              editedItem.item.url = data.url
              if (itemData.parent != data.parent) {
                var len = editedItem.itemPath.length
                var parentItem = editedItem.itemPath[len - 2]
                if (itemData.parent == 0 || itemData.parent == '0') {
                  favs.cachedFavoritesItems.splice(editedItem.index, 1)
                } else {
                  parentItem.children.splice(editedItem.index, 1)
                }

                if (data.parent != 0 && data.parent != '0') {
                  var newParentItem = favs.findItemById(favs.cachedFavoritesItems, data.parent, [])
                  if (newParentItem != null && newParentItem.item != null) {
                    if (newParentItem.item.children == null) {
                      newParentItem.item.children = []
                    }

                    newParentItem.item.children.push(editedItem.item)
                    newParentItem.item.children.sort(favs.getSortFunction())
                  }
                } else {
                  favs.cachedFavoritesItems.push(data)
                  favs.cachedFavoritesItems.sort(favs.getSortFunction())
                }
              }
            }

            if (data.type == 1) {
              favs._refreshUI(data.parent)
              //favs._reloadFavorites(data.parent, true);
            } else {
              favs._refreshUI(favs.currentFolderId)
              //favs._reloadFavorites(favs.currentFolderId, true);
            }

            favs.hideDialog(dialog)
            if (favs.isClientFavorites == true) {
              favs.syncClient()
            }
          } else {
            if (jqXHR.status == 409) {
              // asset already exists
              if (data.type == 1) favs.showErrorAlert(favs.config.strings.errorDuplicateBookmark)
              else favs.showErrorAlert(favs.config.strings.errorDuplicateFolder)
            } else if (jqXHR.status == 304) {
              if (data.type == 1) favs.showErrorAlert(favs.config.strings.errorSameItemName)
              else favs.showErrorAlert(favs.config.strings.errorSameFolderName)
            } else {
              favs.showErrorAlert(favs.getString('errorUnknown'))
            }
          }
        }

        var params = {}
        if (data.title != null) {
          params['name'] = data.title
        }
        if (data.url != null) {
          params['url'] = data.url
        }

        const myElem = document.querySelector('.fav-listing-class')
        const config = JSON.parse(myElem.dataset.config)

        if (itemData.parent != data.parent) {
          var url = '/api/v1/favorites/move/' + itemData.id
          url = url + '?app_id=web&referer=' + encodeURIComponent(window.location.href)
          if (data.parent != null && data.parent != 0 && data.parent != '0')
            url = url + '&destId=' + data.parent

          $.ajax({
            url: url,
            type: 'PUT',
            data: JSON.stringify(params),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            timeout: 3000
          })
            .done(onRequestComplete)
            .fail(function (jqXHR, textStatus, errObj) {
              if (jqXHR.status == 409) {
                // asset already exists
                if (data.type == 1)
                  favsContext.showErrorAlert(favs.config.strings.errorDuplicateBookmark)
                else favsContext.showErrorAlert(favs.config.strings.errorDuplicateFolder)
              } else {
                favsContext.showErrorAlert(favs.config.strings.errorUnknown)
              }
            })
        } else {
          var windowReferer = 'https://www.aol.com/favorites/'
          $.ajax({
            url: '/api/v1/favorites/' + data.id + '?app_id=web&referer=' + windowReferer,
            type: 'PUT',
            data: JSON.stringify(params),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            timeout: 3000
          })
            .done(function (response, textStatus, jqXHR) {
              onRequestComplete(response, textStatus, jqXHR)
            })
            .fail(function (jqXHR, textStatus, errObj) {
              if (jqXHR.status == 409) {
                // asset already exists
                if (data.type == 1) favs.showErrorAlert(favs.config.strings.errorDuplicateBookmark)
                else favs.showErrorAlert(favs.config.strings.errorDuplicateFolder)
              } else {
                favs.showErrorAlert(favs.config.strings.errorUnknown)
              }
            })
        }
      } else {
        this.editItemLocal(data, dialog)
      }
    }

    onItemAdd(event) {
      var data = event.newData
      var favs = this

      var dialog = $(event.target)
      var parentId = data.parent
      if (!data.parent || data.parent == 'undefined' || data.parent == undefined) {
        parentId = 0
      }

      if (!this.onTitleEdit()) return

      if (this.isAuth) {
        var onRequestComplete = function onRequestComplete(response, textStatus, jqXHR) {
          var status = jqXHR ? jqXHR.status : 'null'
          var responseData = null
          if (response) {
            data = $.extend(data, response)
          }

          if (status == 201) {
            favs.hideDialog(dialog)
            if (data.type == 1) {
              favs.addItemToCachedFavoritesItems(data)
              favs._refreshUI(data.parent)

              let currentFavList = document.querySelector('.folder-list-current')

              favs._reloadFavorites(currentFavList, true)
              favs.addBookmark(data.id, data)
              var showConfirmAddbk = true

              if (cookies) {
                var value = cookies.get(favs.showConfirmAddbkName)
                if (value == '0') showConfirmAddbk = false
              }
              if (showConfirmAddbk) favs.showDialogByAction('savebk', data)
            } else {
              var showConfirmAddFdr = true
              if (cookies) {
                var value = cookies.get(favs.showConfirmAddFdrName)
                if (value == '0') showConfirmAddFdr = false
              }
              if (showConfirmAddFdr) {
                favs.showDialogByAction('savefdr', data)
              }
              favs.setFolderItem(data)
              favs.addFolder(data.id, data, false, false)
              favs.addItemToCachedFavoritesItems(data)
              favs._addEventListenersForListOfFolders()
              // favs._refreshUI(data.id)

              favs.folderMap[data.id] = data
              if (favs.folderMap[data.parent] != null) {
                if (favs.folderMap[data.parent].children == null)
                  favs.folderMap[data.parent].children = []
                favs.folderMap[data.parent].children.push(data)
              }
              // favs._refreshUI(data.id)

              favs._reloadFavorites(data.parent, true)
            }
            if (favs.isClientFavorites == true) {
              favs.syncClient()
            }
          } else {
            if (status == 409) {
              // asset already exists
              favs.showErrorAlert(response.comment)
            } else {
              favs.showErrorAlert(favs.config.strings.errorUnknown)
            }
          }
        }

        var action = ''

        var params = {
          name: data.title,
          type: data.type == 2 ? 'folder' : 'item'
        }

        // parentId "0" is the default parent id for root items.
        if (parentId != '0') {
          params['parent_id'] = parentId
        }

        if (data.type == 1) {
          params['url'] = data.url
        }

        const myElem = document.querySelector('.fav-listing-class')
        const config = JSON.parse(myElem.dataset.config)

        $.ajax({
          url: '/api/v1/favorites?app_id=web&referer=' + window.location.href,
          type: 'POST',
          data: JSON.stringify(params),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json'
        })
          .done(onRequestComplete)
          .fail(function (jqXHR, textStatus, errObj) {
            if (jqXHR.status == 409) {
              // asset already exists
              //TODO error message not showing up
              if (data.type == 1) favs.showErrorAlert(favs.config.strings.errorDuplicateBookmark)
              else favs.showErrorAlert(favs.config.strings.errorDuplicateFolder)
            } else {
              favs.showErrorAlert(favs.config.strings.errorUnknown)
            }
          })
      } else {
        this.addItemLocal(data, dialog)
      }
    }

    getFolderParams(id, currentPage, start, sort, path) {
      currentPage = currentPage || this.currentPage || 1
      start = start || this.count * (currentPage - 1) || 0
      sort = sort || this.currentSort || ''
      path = path || this.getFolderPath(id)

      return (
        '&pathFilter=' +
        path +
        '&start=' +
        encodeURIComponent(start) +
        '&currentPage=' +
        encodeURIComponent(currentPage) +
        '&sort=' +
        encodeURIComponent(sort) +
        '&count=' +
        this.count
      )
    }

    selectFolder(id, currentPage, start, sort, force, data) {
      var self = this
      var params = this.getFolderParams(id, currentPage, start, sort)

      if (this.currentFolderId != id || force === true) {
        if (this.isAuth) {
          var onRequestComplete = function onRequestComplete(response, textStatus, jqXHR) {
            if (response && response.hasOwnProperty('folders') && response.hasOwnProperty('html')) {
              if (response.folders.parentId >= 0 && response.folders.totalBookmarks >= 0) {
                self.currentFolderId = id
                self.currentPage = parseInt(response.pagination.currentPage)

                // Merge response data into local properties
                self.updateFolderData(response.folders)

                // Update the DOM
                self.updateContent(response.html)
                self.updateCrumbNav()
                self.updateNav()
                this.makeAccessibityCompat()
                if ($('.favorites-content div:nth-child(2)').hasClass('favorites-empty')) {
                  if ($('.favorites-nav li.favorites-current').length) {
                    $('.favorites-nav li.favorites-current a:first-child').focus()
                  } else if (self.currentFolderId == 0) {
                    $('.favorites-nav li:first-child a:first-child').focus()
                  } else {
                    $('.favorites-content div:first-child a:first-child').focus()
                  }
                } else {
                  $('.favorites-content div:first-child a:first-child').focus()
                }
              } else {
                console.error('selectFolder[ERROR]: Invalid data or object not found')
                self.showNotice(self.getString('errorUnknown'), true)
              }
            } else {
              console.error('selectFolder[ERROR]: Invalid response format')
              self.showNotice(self.getString('errorUnknown'), true)
            }
          }
          if (data && data.hasOwnProperty('folders') && data.hasOwnProperty('html')) {
            onRequestComplete(data, 'local-data')
          }
        }
      }
    }

    getFolderData(id, successFunc, errorFunc, folderPath) {
      var self = this
      folderPath = folderPath || '/'

      var localStoredFavoritesItems = self._getLocalStorageFavorites()
      var levelItems = localStoredFavoritesItems

      if (folderPath != '/') {
        var paths = folderPath.split('/')
        var leafNode = null
        for (var i = 0; i < paths.length; ++i) {
          var path = paths[i]
          var found = false
          for (var j = 0; j < levelItems.length; ++j) {
            if (levelItems[j].title == path) {
              levelItems = levelItems[j].children
              found = true
              break
            }
          }

          if (!found) {
            levelItems = []
            break
          }
        }
      }

      var folderObj = {
        type: 2,
        id: id,
        children:
          levelItems == null
            ? []
            : levelItems.filter(function (item) {
              return item.type == 2
            })
      }

      successFunc(folderObj, null)
    }

    addFolderData(title, parentId, successFunc, errorFunc) {
      var _me = this
      const favsContext = this
      var data = {
        name: title,
        type: 'folder'
      }

      if (parentId != 0 && parentId != '0') {
        data['parent_id'] = parentId
      }

      const myElem = document.querySelector('.fav-listing-class')
      const config = JSON.parse(myElem.dataset.config)

      $.ajax({
        url: '/api/v1/favorites?app_id=web&referer=' + window.location.href,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      })
        .done(function (response, textStatus, jqXHR) {
          if (jqXHR.status == 201) {
            data = $.extend(data, response)
            favsContext.hideErrorAlert()
            successFunc(data, jqXHR)
            //favsContext._reloadFavorites(0, true);
          } else {
            if (response.comment != null && (code == 1027 || code < 0)) {
              // asset already exists
              favsContext.showMessageText(response.comment)
            } else {
              favsContext.showMessageText(favsContext.config.strings.errorUnknown)
              errorFunc('Invalid response data', jqXHR)
            }
          }
        })
        .fail(function (jqXHR, textStatus, errObj) {
          favsContext.showErrorAlert(favsContext.config.strings.errorUnknown)
          errorFunc(textStatus, jqXHR)
        })
    }

    addItemLocal(data, target) {
      var self = this
      var id = '_local' + favSelf.itemGuid++
      var modTime = new Date().getTime()

      data = $.extend(
        {
          id: id,
          modTime: modTime
        },
        data
      )

      var response = this.validateItemLocal(data)

      if (response.status == 0) {
        this.hideDialog(target)

        // Show signin reminder on first time adding a bookmark
        if (!this.addedFirstLocalBookmark) {
          this.addedFirstLocalBookmark = true
        }

        if (data.type == 1) {
          self.setBookmarkItem(data)
          self.addBookmark(data.id, data)
          var showConfirmAddbk = true
          if (cookies) {
            if (cookies.get(self.showConfirmAddbkName) == '0') showConfirmAddbk = false
          }

          if (showConfirmAddbk) self.showDialogByAction('savebk', data)
        } else {
          self.setFolderItem(data)
          self.addFolder(data.id, data, false, true)
        }
        self.updateContent()
      } else {
        if (response.comment) {
          this.showErrorAlert(response.comment)
        } else {
          this.showErrorAlert(self.getString('errorUnknown'))
        }
      }
    }

    editItemLocal(data, target) {
      var modTime = new Date().getTime()

      data.modTime = modTime

      var response = this.validateItemLocal(data)

      if (response.status == 0) {
        this.hideDialog(target)
        //this.hideNotice(true);

        if (data.type == 1) {
          this.setBookmarkItem(data)
          this.updateBookmark(data.id, data)
        } else {
          this.setFolderItem(data)
          this.updateFolder(data.id, data)
        }
        this.updateContent()
      } else {
        if (response.comment) {
          this.showErrorAlert(response.comment)
        } else {
          this.showErrorAlert(self.getString('errorUnknown'))
        }
      }
    }

    deleteItemLocal(data, target) {
      var response = {
        status: 0,
        code: -1,
        comment: ''
      }

      if (response.status == 0) {
        this.hideDialog(target)
        //this.hideNotice(true);

        if (data.type == 1) {
          favsContext.removeBookmarkItem(data)
          favsContext.removeBookmark(data.id)
        } else {
          favsContext.removeFolderItem(data)
          favsContext.removeFolder(data.id)
        }
        favsContext.updateContent()
      } else {
        if (response.comment) {
          favsContext.showErrorAlert(response.comment)
        } else {
          favsContext.showErrorAlert(favsContext.config.strings.errorUnknown)
        }
      }
    }

    validateItemLocal(data) {
      var response = {
        status: 0,
        code: -1,
        comment: ''
      }

      // Check for duplicates
      for (var i in this.localBookmarkData) {
        var item = this.localBookmarkData[i]
        if (item.id != data.id) {
          if (item.title == data.title) {
            response.status = 1
            response.code = 1021
            response.comment = this.config.strings.errorDuplicateBookmark
            break
          }
        }
      }

      return response
    }

    /*
     * DOM manipulation methods
     */

    removeBookmark(id, parentId) {
      let bookmark = document.querySelector('.favorites-container').querySelector('#b' + id)
      if (bookmark.classList.contains('favorites-last')) {
        let currentFolder = document.querySelector('.folder-list-current')
        let allInFolder = currentFolder.querySelectorAll('.favorites-item')
        if (allInFolder.length > 1) {
          let newLastItem = allInFolder[allInFolder.length - 2]
          newLastItem.classList.add('favorites-last')
        } else {
          this.addFavoritesEmptyDiv(currentFolder)
        }
      }
      bookmark.remove()
      this._deleteItemFromLocalStorage(id)
      this.bookmarksTotal--
      this._updateFavoriteNumber()
    }

    removeFolder(id, parentId) {
      this._reloadFavorites(parentId)
      /*
          var element = this.nav.find('#f' + id);
          this._deleteFromFolderMap(id, parentId);
          this._deleteItemFromLocalStorage(id);
          if (element.length) {
            // If is current folder, select parent folder
            var parentElement = this.nav.find("#f" + parentId);
            if (parentId == 0) {
              parentElement = $(this.config.modules.favoritesNavRoot);
            }
            if (this.currentFolderId == id) {
              parentElement.trigger('click');
              this.refreshIndividualAds();
              this.track();
              // Else just remove it
            } else {
              this.nav.find('#f' + id).remove();
              parentElement.trigger('click');
            }
          }
          */
    }

    updateBookmark(id, data, responseData) {
      var element = $('.favorites-container').find('#b' + id)
      if (element.length) {
        // If bookmark was moved to different folder, just remove it
        if (data.parent != this.currentFolderId) {
          this.removeBookmark(id, responseData)

          // Else update the existing bookmark
        } else {
          var titleEl = element.find('h3').text(data.title)
          element.find('.favorites-item-domain').text(this.getUrlDomain(data.url))

          element.find('.favorites-item-date').text(this.getFormattedTime(data.updated_at))

          element
            .find('a:first')
            .attr('href', data.url)
            .attr('title', data.title)

          this.truncateElementText(titleEl, element.width() - this.bookmarkWidthOffset)
        }
      }
    }

    updateFolder(id, data) {
      var element = this.nav.find('#f' + id)
      if (element.length) {
        // If is subfolder, and was moved, just remove it
        if (id != this.currentFolderId && data.parent != this.currentFolderId) {
          this.removeFolder(id)

          // If folder is current folder, and moved, update the rest of the nav around it
        } else if (id == this.currentFolderId && data.parent != this.getParentId(id)) {
          this.selectFolder(this.currentFolderId, this.currentPage, null, null, true)

          // Else update the existing folder
        } else {
          var titleEl = element
            .children()
            .first()
            .text(data.title)
            .attr('title', data.title)

          this.truncateElementText(titleEl, element.width())

          this.updateCrumbNav()
        }
      }
    }

    addBookmark(id, data, responseData) {
      var element = $('.favorites-container').find('#b' + id)
      if (element.length) {
        if (this.isAuth) {
          var last = ''
          var count = $('.favorites-item.local').length

          if (count == 0) {
            last = 'favorites-last'
          }
          var linkEl, toolbarEl, contentEl, titleEl, sanitizedTitle, sanitizedUrl
          sanitizedTitle = this.sanitizeInput(data.title)
          sanitizedUrl = this.sanitizeURLInput(data.url)

          element = $('.favorites-item')
            .prependTo('favorites-content')
            .append(
              (linkEl = $(
                '<a href="' + sanitizedUrl + '" title="' + sanitizedTitle + '" target="_blank"></a>'
              ))
            )
            .append(
              '<div class="favorites-item-date">' +
              this.getFormattedTime(data.updated_at) +
              '</div>'
            )
            .append((toolbarEl = $('<div class="favorites-item-toolbar">')))

          linkEl
            .append(
              '<img src="" class="favorites-item-icon" alt="' +
              this.config.strings.assetIconAltText +
              '" />'
            )
            .append((contentEl = $('<div class="favorites-item-content">')))

          contentEl
            .append((titleEl = $('<h3>' + sanitizedTitle + '</h3>')))
            .append(
              '<span class="favorites-item-domain">' + this.getUrlDomain(sanitizedUrl) + '</span>'
            )

          toolbarEl
            .append(
              '<a name="edit" class="navicon-edit" title="' +
              this.config.strings.editButtonText +
              '"></a>'
            )
            .append(
              '<a name="delete" class="navicon-close" title="' +
              this.config.strings.deleteButtonText +
              '"></a>'
            )

          this.truncateElementText(titleEl, element.width() - this.bookmarkWidthOffset)
        }

        // Update the content list and total
        // this.selectFolder(this.currentFolderId, this.currentPage, null, null, true, data)
      }
    }

    addFolder(id, data, selected, addToTop) {
      var favs = this
      var element = this.nav.find('#f' + id)
      var edtpos = self.fdrpos
      var addpos = self.fdrpos + 1
      var liAttr = ''
      if (this.dragdropEnable == 'true') {
        liAttr = 'draggable="true"'
      }

      if (
        !element.length &&
        data.parent != null &&
        (data.parent == this.currentFolderId || id == this.currentFolderId)
      ) {
        element = $('<li class="folder" id="f' + id + '"></li>').append(
          $('<div class="favorites-item-toolbar"></div>')
            .append(
              '<a name="edit" data-ylk="sec:favorites;itc:1;elm:folder-edit;subsec:edit-folder;" class="favorites-edit-button navicon-edit lnid-sec5_lnk' +
              edtpos +
              '" title="Edit"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="-253.056 -244.987 512 512"><title>Edit folder</title><path fill="#E6248A" d="M-250.597 265.639l7.023-130.524L45.645-154.103 168.656-31.092l-289.157 289.158-130.096 7.573zm45.052-113.559l-3.897 72.242 71.896-4.162L113.683-31.07 45.645-99.11l-251.19 251.19zM182.659-45.013L59.955-167.719l62.872-62.874c13.8-13.8 36.297-13.799 50.095 0l72.633 72.632c13.798 13.799 13.779 36.274-.021 50.074l-62.875 62.874zM114.97-167.699l67.731 67.732 32.948-32.947-67.731-67.733-32.948 32.948z"/></svg></a>'
            )
            .append(
              '<a name="add" data-ylk="sec:favorites;itc:1;elm:btn;" class="favorites-add-button navicon-add lnid-sec5_lnk' +
              addpos +
              '" title="Add"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="-251.05 -251.05 512 512"><title>Add folder</title><path fill="#E6248A" d="M260.932 56.654V-46.772H56.655V-251.05H-46.772v204.278H-251.05V56.654h204.278v204.278H56.655V56.654z"/></svg></a>'
            )
        )

        //add corresponding list
        let folderList = document.createElement('div')
        folderList.setAttribute('id', `folder-id-f${id}`)
        folderList.setAttribute('class', 'folder-item-list')
        let listContainer = document.querySelector('.favorites-content')
        listContainer.appendChild(folderList)
        favs.addFavoritesEmptyDiv(folderList)

        // Position the nav item in the list
        if (selected) {
          // If selected, always make it the first item
          element.prependTo(this.nav)
        } else if (addToTop) {
          var current = this.nav.find('.favorites-current')
          if (current.length) {
            // If there is a selected item, insert immediately after it
            element.insertAfter(current)
          } else {
            // Else add to top of list'
            element.prependTo(this.nav)
            if (this.iPadEditMode) {
              this.onEditModeClick(true)
            }
          }
          this.updateLnid(data.type)
        } else {
          // Add item to end of list
          var existingFolders = this.nav.find('li')
          var done = false
          for (var i = 0; i < existingFolders.length; ++i) {
            var f = $(existingFolders[i])
            var title = f
              .find('a[name="select"]')
              .attr('title')
              .toLowerCase()
            if (title.localeCompare(data.title.toLowerCase()) > 0) {
              done = true
              element.insertBefore(f)
              break
            }
          }
          if (!done) element.appendTo(this.nav)
        }

        if (selected) {
          var dragAtt = ''
          if (this.dragdropEnable == 'true') {
            dragAtt = 'draggable="true"'
          }
          element.prepend('<a ' + dragAtt + '>' + favs.htmlEncode(data.title) + '</a>')
          // .addClass('favorites-current')
        } else {
          var elmpos =
            element
              .parent('ul')
              .children('li[id]')
              .index(element) + 1
          if (this.getParentId(id) > 0) {
            element.closest('li[id^="f"]').addClass('subfdr')
          }
          element.prepend(
            '<a name="select" class="lnid-sec4_lnk' +
            elmpos +
            '" ' +
            liAttr +
            '>' +
            favs.htmlEncode(data.title) +
            '</a>'
          )
        }

        var titleEl = element
          .children()
          .first()
          .attr('title', data.title)
          .attr('data-id', data.id)

        // Truncate title if too wide
        this.truncateElementText(titleEl, element.width())
        self.fdrpos = self.fdrpos + 2
      }
    }

    updateLnid(type) {
      if (!type) return
      self.fdrpos = 1
      if (type == 2) {
        var edtpos = self.fdrpos
        var addpos = self.fdrpos + 1
        var element = this.nav.find('li')
        if (element.length) {
          element.find('.favorites-item-toolbar [name=edit]').each(function () {
            var eclss = utils.findClass(this, /lnid-.*/)
            $(this).removeClass(eclss)
            $(this).addClass('lnid-sec5_lnk' + edtpos)
            edtpos = edtpos + 2
          })
          element.find('.favorites-item-toolbar [name=add]').each(function () {
            var aclss = utils.findClass(this, /lnid-.*/)
            $(this).removeClass(aclss)
            $(this).addClass('lnid-sec5_lnk' + addpos)
            addpos = addpos + 2
          })
        }
      }
    }

    updateCrumbNav() {
      // leave some extra room between sort and path
      var maxPathWidth = 75
      var folderObj = this.getFolderItem(this.currentFolderId)

      if (this.breadcrumb.length) {
        this.breadcrumb.html(
          '<div class="tool-nav"><a class="favesroot " title="' +
          this.config.strings.favesroottooltip +
          '">' +
          this.config.strings.favesroottext +
          '</a></div><div class="tool-nav fav-addtool favorites-tools-addfav"><a class="navicon-add" title="' +
          this.config.strings.addfaveicontooltip +
          '">+</a></div>'
        )
        $('.favorites-container')
          .find('.favesroot')
          .bind('click', $.proxy(self.onBookmarksButtonClicked, self))
      }
    }

    truncatePath(maxWidth, folderObj) {
      $first = $('ul.favorites-breadcrumb li').first()
      this.truncateElementText($first, maxWidth)

      if (folderObj != null) {
        this.truncateElementText($first, maxWidth / 2)
        var text = $first.text()
        $first.html('<li id="n' + folderObj.id + '"><a>' + text + '</a></li>')
      }
    }

    updateNav() {
      var favs = this
      this.nav.empty()
      var folderObj = this.getFolderItem(this.currentFolderId)
      this.addFolder(folderObj.id, folderObj, true)

      if (folderObj.children && folderObj.children.length) {
        $.each(folderObj.children, function (i, obj) {
          favs.addFolder(obj.id, obj, false)
        })
      }
      while (folderObj.parent != null) {
        if (folderObj.id != this.currentFolderId) {
          var liAttr = ''
          if (this.dragdropEnable == 'true') {
            liAttr = 'draggable="true"'
          }
          this.nav.prepend(
            '<li id="f' +
            folderObj.id +
            '" class="pfdr"><a name="select" title="' +
            favs.htmlEncode(folderObj.title) +
            '" ' +
            liAttr +
            '>' +
            favs.htmlEncode(folderObj.title) +
            '</a><div class="favorites-item-toolbar"><a name="edit" class="favorites-edit-button navicon-edit" title="edit">' +
            '</a><a name="add" class="favorites-add-button" title="Add"></a></div></li>'
          )
          if (folderObj.title.length > 30) {
            var el = this.nav.find('#f' + folderObj.id)
            this.truncateElementText(el.find("[name='select']").first(), el.width())
          }
        }

        folderObj = this.getFolderItem(folderObj.parent)
      }

      if (this.iPadEditMode) {
        this.onEditModeClick(true)
      }
    }

    updateNavStyle() {
      this.nav.find('li:last-child').addClass('lst')
    }

    updateContent(html) {
      self.fdrpos = 1
      if (this.isAuth) {
        this.content.empty()
        if (html) {
          this.content.html(html)
          this.initContent()
        }
      } else {
        if (this.localBookmarkData.length) {
          this.emptyBookmarksMessage.detach()
        } else {
          this.content.prepend(this.emptyBookmarksMessage)
        }
      }
      if ($('.favorites-page-nav li').length) {
        $('.favorites-page-nav li a').each(function () {
          var elmpos =
            $(this)
              .parent()
              .index(this.parent) + 1
          $(this).addClass('lnid-sec8_lnk' + elmpos)
        })
      }

      $(document).scrollTop(0)

      if (this.iPadEditMode) {
        this.onEditModeClick(true)
      }
    }

    /*
     * Dialog methods
     */

    showDialog(dialog) {
      if (dialog && dialog.hasClass('favorites-dialog')) {
        this.hideAllDialogs()

        if (this.modalDialogs && !$('.favorites-dialog-background').length) {
          var dialogBackground = $('<div class="favorites-dialog-background"></div>').insertBefore(
            dialog
          )
        }

        $(document).on(
          'keyup',
          {
            dialog: dialog
          },
          $.proxy(this.onKeyUp, this)
        )
        $(window).on(
          'resize',
          {
            dialog: dialog
          },
          $.proxy(this.onResize, this)
        )
        this.makeAccessibityCompat()
        dialog.css('visibility', 'visible')
        dialog.fadeTo(150, 1, 'swing')
        setTimeout(() => {
          rapidReady(rapid => {rapid.addModules('favorites-dialog')})
          }, 500)
      }
    }

    hideDialog(dialog, animate) {
      var self = this
      if (dialog && dialog.hasClass('favorites-dialog') && dialog.css('visibility') == 'visible') {
        animate = animate == null ? true : animate

        $('.favorites-dialog-background').remove()

        $(document).unbind('keyup', $.proxy(this.onKeyUp, this))
        $(window).unbind('resize', $.proxy(this.onResize, this))

        if (animate) {
          dialog.fadeTo(150, 0, 'swing', function () {
            self.disposeDialog($(this))
          })
        } else {
          this.disposeDialog(dialog)
        }
      }
    }

    hideAllDialogs(animate) {
      var favs = this
      $('.favorites-dialog').each(function () {
        var dialog = $(this)

        favs.hideDialog(dialog, animate)
        setTimeout(() => {
          rapidReady( rapid => { rapid.removeModule('favorites-dialog')})
        }, 500)
      })
    }

    onKeyUp(event) {
      if (event.data.dialog) {
        if (event.keyCode == 27) {
          // ESC key
          event.stopPropagation()
          this.hideDialog(event.data.dialog)
        } else if (event.keyCode == 13) {
          // Enter key
          event.stopPropagation()
          if (!$(event.target).closest('.selector-menu-items').length) {
            event.data.dialog.find('a.favorites-save-button').click()
          } else {
            $('.navicon-down').focus()
            this.currentFolderId = parseInt(
              $(event.target)
                .parent('li')
                .attr('id')
                .replace('s', '')
            )
          }
        }
      }
    }

    onResize(event) {
      if (event.data.dialog) {
        this.positionDialog(event.data.dialog)
      }
    }

    disposeDialog(dialog) {
      if (dialog && dialog.hasClass('favorites-dialog')) {
        //&& dialog.size() TODO
        dialog.remove()
      }
    }

    positionDialog(dialog) {
      if (dialog) {
        // 40 pixels from top of window
        var top = Math.max(0, 40 + $(window).scrollTop())
        // center width
        var left = Math.max(
          0,
          ($(window).width() - dialog.outerWidth()) / 2 + $(window).scrollLeft()
        )

        dialog.css('top', top + 'px')
        dialog.css('left', left + 'px')
      }
    }

    showDialogByAction(action, data) {
      var self = this
      data.title = this.htmlEncode(data.title)
      if (action) {
        var base = '/api/modules/'
        var favs = this
        switch (action) {
          case 'add':
            var addModal = add_modal()

            var html = addModal.innerHTML.replace('fav-heart', data.title)
            var dialog = favs.createDialog('favorites-add-dialog', data, html)

            favs.showDialog(dialog)
            $('#addbookmark').addClass('fnp f-' + data.parent)
            $('#addfolder').addClass('fnp f-' + data.parent)
            break

          case 'addfdr':
            var addFolderModal = add_folder_modal()
            var html = addFolderModal.innerHTML
            var dialog = favs.createDialog('favorites-add-dialog', data, html)
            favs.showDialog(dialog)
            $('#favorites-input-name').focus()
            break
          case 'addbk':
            var addFavoritesModal = add_favorites_modal()
            var html = addFavoritesModal.innerHTML
            var dialog = favs.createDialog('favorites-add-dialog', data, html)

            favs.showDialog(dialog)
            $('#favorites-input-name').focus()
            break
          case 'edtfdr':
            var editFolderModal = edit_folder_modal().innerHTML
            var html = editFolderModal.replace('_FOLDERNAME_', data.title)
            var dialog = favs.createDialog('favorites-edit-dialog', data, html)

            favs.showDialog(dialog)
            $('#favorites-input-name').focus()
            break
          case 'edtbk':
            var editFavoriteModal = edit_favorites_modal().innerHTML
            var html = editFavoriteModal.replace('_BOOKMARKNAME_', data.title)
            var dialog = favs.createDialog('favorites-edit-dialog', data, html)

            favs.showDialog(dialog)
            $('#favorites-input-name').focus()
            break
          case 'delfdr':
            var deleteFolderModal = delete_folder_modal().innerHTML
            var html = deleteFolderModal.replace('_FOLDERNAME_', data.title)
            var dialog = favs.createDialog('favorites-delete-dialog', data, html)

            favs.showDialog(dialog)
            $('.favorites-cancel-button').focus()
            break
          case 'delbk':
            var deleteFavoriteModal = delete_favorite_modal().innerHTML
            var parentfolder = favs.getFolderItem(data.parent)
            var parentTitle = parentfolder ? parentfolder.title : ''
            var html = ''

            if (parentfolder.id > 0) html = deleteFavoriteModal.replace('_FOLDERNAME_', parentTitle)
            else html = deleteFavoriteModal.replace('_FOLDERNAME_/', '')

            html = html.replace('_BOOKMARKNAME_', data.title)
            var dialog = favs.createDialog('favorites-delete-dialog', data, html)

            favs.showDialog(dialog)
            $('.favorites-cancel-button').focus()
            break
          case 'savebk':
            var parentfolder = favs.getFolderItem(data.parent)
            var parentTitle = parentfolder ? parentfolder.title : self.getString('rootFolderName')
            var saveFavoriteModal = save_favorites_modal()
            var html = saveFavoriteModal.innerHTML
              .replace('_BOOKMARKNAME_', data.title)
              .replace('_FOLDERNAME_', parentTitle)
            var dialog = favs.createDialog('favorites-saved-dialog', data, html)

            favs.showDialog(dialog)
            $('.favorites-confirmsave-button').addClass('fnp f-' + data.parent)
            $('#favorites-input-checkbox').focus()
            break
          case 'savefdr':
            var parentfolder = favs.getFolderItem(data.parent)
            var parentTitle = parentfolder ? parentfolder.title : self.getString('rootFolderName')
            var saveFolderModal = save_folder_modal().innerHTML
            var html = saveFolderModal
              .replace('_FOLDERNAME_', data.title)
              .replace('__PARENTFOLDER__', parentTitle)
            var dialog = favs.createDialog('favorites-saved-dialog', data, html)

            favs.showDialog(dialog)
            $('.favorites-confirmsave-button').addClass('fnp f-' + data.parent)
            $('#favorites-input-checkbox').focus()
            break
          default:
            break
        }
      }
    }

    createDialog(className, data, html) {
      var favs = this
      var self = this
      var type = this.getItemType(data.type)
      var typeClass = 'favorites-' + type
      this.pasting = false
      this.currentTitle = ''
      this.keyStroke = false

      this.disposeDialog($('.' + className + '.' + typeClass))

      var dialog = $(
        '<div class="' +
        className +
        ' favorites-dialog" id="favorites-dialog" data-rapid="true"></div>'
      )
        .append(html)
        .css('opacity', 0)
        .css('visibility', 'hidden')
        .addClass(typeClass)

      dialog.find('.favorites-dialog-content').addClass('clearfix')
      if (!this.isAuth) {
        dialog.find('#favorites-input-selector, label[for="favorites-input-selector"]').remove()
        dialog
          .find('.favorites-dialog-content > .favorites-input')
          .last()
          .addClass('favorites-last')
      }
      dialog.appendTo(document.body)

      this.hideErrorAlert()
      if (this.isAuth) {
        this.hideNotice()
      }

      // Show/hide error messages originating from folderSelector
      $(document).bind(
        'selector-error.' + this.selectorNamespace,
        $.proxy(function (event) {
          var message = event.error
          if (message) {
            this.showErrorAlert(message)
          } else {
            this.hideErrorAlert()
          }
        }, this)
      )

      var inputSelector = dialog.find('.favorites-dialog-content #favorites-input-selector')

      dialog.find('.favorites-dialog-close-button').bind(
        'click',
        {
          dialog: dialog
        },
        function (event) {
          $(dialog).trigger({
            type: 'dialog-canceled.' + self.namespace,
            itemData: data
          })
          var pclss = utils.findClass(this, /fav-.* /).replace('fav-', '')
          //omn.omcl(pclss);
        }
      )

      //bind cancel and submit button
      var cancelButton = dialog.find('a.favorites-cancel-button').bind(
        'click',
        {
          dialog: dialog
        },
        function (event) {
          dialog.trigger({
            type: 'dialog-canceled.' + self.namespace,
            itemData: data
          })
          var cclss = utils.findClass(this, /fav-.* /).replace('fav-', '')
          //omn.omcl(cclss);
        }
      )

      // Initialize dialog with initial data and attach event handlers
      if (className == 'favorites-delete-dialog') {
        dialog.deleting = 0
        var confirmButton = dialog.find('a.favorites-save-button').bind(
          'click',
          {
            dialog: dialog
          },
          function (event) {
            if (dialog.deleting === 0) {
              dialog.deleting = 1
              dialog.trigger({
                type: 'delete-item.' + self.namespace,
                itemData: data,
                originalDialog: dialog
              })
              var cclss = utils.findClass(this, /fav-.* /).replace('fav-', '')
            }
            //omn.omcl(cclss);
          }
        )
      } else if (className == 'favorites-edit-dialog') {
        $('#favorites-input-name').val(data.title)
        $('#favorites-input-url').val(data.url)

        $('#favorites-input-name').bind('keydown', $.proxy(favs.onKeydown, favs))
        $('#favorites-input-name').bind('keyup', $.proxy(favs.onTitleEdit, favs))
        $('#favorites-input-url').bind('keyup', $.proxy(favs.onUrlEdit, favs))
        $('.selector-add-input').on('keyup', $.proxy(favs.onAddInputEdit, favs))
        $('.selector-add-input').on('keydown', $.proxy(favs.onKeydown, favs))

        var confirmButton = dialog.find('a.favorites-save-button').bind(
          'click',
          {
            dialog: dialog
          },
          function (event) {
            var newData = favs.cloneObject(data)
            if (data.type == 1) {
              newData.url = favs.sanitizeURLInput($('#favorites-input-url').val())
            }
            newData.title = favs.sanitizeInput($('#favorites-input-name').val())
            // Get the selected parent id from the folderSelector plugin
            if (inputSelector.length) {
              newData.parent = inputSelector.attr('data-selected-id')
            }

            var validTitle = favs.validateInput(newData.title)
            var validUrl = data.type == 2 || favs.validateURLInput(newData.url.trim())

            if (validTitle && validUrl) {
              dialog.trigger({
                type: 'update-item.' + self.namespace,
                itemData: data,
                newData: newData
              })
            } else {
              if (!validTitle) {
                $('#favorites-input-name').addClass('favorites-error')
              }
              if (!validUrl) {
                $('#favorites-input-url').addClass('favorites-error')
              }
              var message =
                !validTitle && !validUrl
                  ? self.getString('errorInvalidTitleUrl')
                  : !validTitle
                    ? self.getString('errorInvalidTitle')
                    : self.getString('errorInvalidUrl')
              self.showErrorAlert(message)
            }
            var pclss = utils.findClass(this, /fav-.* /).replace('fav-', '')
            //omn.omcl(pclss);
          }
        )

        var deleteButton = dialog.find('a.favorites-delete-button').bind(
          'click',
          {
            dialog: dialog
          },
          function (event) {
            if (data.type == 1) {
              favs.showDialogByAction('delbk', data)
            } else {
              favs.showDialogByAction('delfdr', data)
            }
            var cclss = utils.findClass(this, /fav-.* /).replace('fav-', '')
            //omn.omcl(cclss);
          }
        )
        if (this.isAuth) {
          inputSelector.folderSelector({
            folderMap: this.folderMap,
            startId: data.parent,
            currentId: data.id,
            strings: this.strings,
            timeout: this.timeout,
            maxFolderDepth: this.maxSubFolderDepth,
            getFolderData: $.proxy(this.getFolderData, this),
            addFolderData: $.proxy(this.addFolderData, this),
            favoritesItems: this._getLocalStorageFavorites()
          })
        }
      } else if (className == 'favorites-add-dialog') {
        $('#favorites-input-name').val(data.title)
        $('#favorites-input-url').val(data.url)

        $('#addfolder').bind('click', $.proxy(favs.onFolderAddClicked, favs))
        $('#addbookmark').bind('click', $.proxy(favs.onBookmarkAddClicked, favs))

        $('#favorites-input-name').bind('keyup', $.proxy(favs.onTitleEdit, favs))
        $('#favorites-input-name').bind('keydown', $.proxy(favs.onKeydown, favs))
        $('#favorites-input-url').bind('keyup', $.proxy(favs.onUrlEdit, favs))

        var favs = this
        var confirmButton = dialog.find('a.favorites-save-button').bind(
          'click',
          {
            dialog: dialog
          },
          function (event) {
            var newData = favs.cloneObject(data)
            if (data.type == 1) {
              newData.url = favs.sanitizeURLInput($('#favorites-input-url').val())
            }
            newData.title = favs.sanitizeInput($('#favorites-input-name').val())
            // Get the selected parent id from the folderSelector plugin
            if (inputSelector.length) {
              newData.parent = inputSelector.attr('data-selected-id')
            }

            var validTitle = favs.validateInput(newData.title)
            var validUrl = data.type == 2 || favs.validateURLInput(newData.url.trim())

            if (validTitle && validUrl) {
              dialog.trigger({
                type: 'add-item.' + self.namespace,
                itemData: data,
                newData: newData
              })
            } else {
              if (!validTitle) {
                $('#favorites-input-name').addClass('favorites-error')
              }
              if (!validUrl) {
                $('#favorites-input-url').addClass('favorites-error')
              }
              var message =
                !validTitle && !validUrl
                  ? favs.config.strings.errorInvalidTitleUrl
                  : !validTitle
                    ? favs.config.strings.errorInvalidTitle
                    : favs.config.strings.errorInvalidUrl
              favs.showErrorAlert(message)
            }
            var pclss = utils.findClass(this, /fav-.* /).replace('fav-', '')
          }
        )
        if (this.isAuth) {
          var dataParent = data.parent
          if (data.newparentId) dataParent = data.newparentId
          inputSelector.folderSelector({
            folderMap: this.folderMap,
            startId: dataParent,
            strings: this.strings,
            timeout: this.timeout,
            maxFolderDepth: this.maxSubFolderDepth,
            showAddUI: data.type == 1,
            getFolderData: $.proxy(this.getFolderData, this),
            addFolderData: $.proxy(this.addFolderData, this),
            favoritesItems: this.cachedFavoritesItems
          })
        }
      } else if (className == 'favorites-saved-dialog') {
        var confirmaddbkButton = dialog.find('a.favorites-confirmsave-button').bind(
          'click',
          {
            dialog: dialog
          },
          function (event) {
            var npid = favs.getParentId(data.id)
            if (data.newparentId) npid = data.newparentId
            if (data.type == 1) {
              favs.onBookmarkAddClicked(self)
              //omn.omcl("confrmaddbk-addbtn");
            } else {
              favs.onFolderAddClicked(self)
              //omn.omcl("confrmaddfdr-addbtn");
            }
          }
        )

        var confirmaddbkCheckbox = dialog.find('input#favorites-input-checkbox').click(function () {
          var showConfirmAdd = '1'
          var confirmAddCookie = self.showConfirmAddbkName
          if ($(this).is(':checked')) showConfirmAdd = '0'

          var exdate = new Date()
          exdate.setFullYear(exdate.getFullYear() + 2)
          if (data.type == 2) {
            confirmAddCookie = self.showConfirmAddFdrName
          }
          cookies.set(confirmAddCookie, showConfirmAdd, exdate.toGMTString())
        })

        var confirmaddbkForm = $('#favorites-form-save')
        confirmaddbkForm.keyup(function (e) {
          if (e.keyCode == 13 && !$(e.target).hasClass('favorites-dialog-close-button')) {
            confirmaddbkButton.click()
          }
        })
      }

      $('#favorites-input-name').bind('paste', $.proxy(this.onTitlePaste, this))
      $('.selector-add-input').bind('paste', $.proxy(this.onTitlePaste, this))

      this.positionDialog(dialog)

      return dialog
    }

    setupAdRefresh() {
      // build array of ad div ids
      self.adDivIds = []
      if (self.enableRefresh == true) {
        // build list of divids
        if (document.querySelectorAll('.adsDivs'))
          $(adsDivs).each(function (idx) {
            self.adDivIds[idx] = $(this).attr('id')
          })
      }
    }

    refreshIndividualAds() {
      var adsDivs = document.querySelectorAll('.adsDivs')
      for (var i = 0; i < adsDivs.length; i++) {
        //refreshing all ads on page - right rail and footer mobile
        adsReloadAd(adsDivs[i])
      }
    }

    onTitleEdit(event) {
      var title = $('#favorites-input-name').val()
      var titleChanged = title != self.currentTitle
      if (titleChanged) {
        if (title.length >= self.maxFolderNameLength) {
          if (self.pasting) {
            this.showErrorAlert(this.getString('errorTitleTruncate'))
          }
        } else {
          this.hideErrorAlert()
        }
        this.currentTitle = title
      }

      var noError = true
      if (title.length > self.maxFolderNameLength) noError = false

      return noError
    }

    onAddInputEdit(event) {
      var title = $('.selector-add-input').val()

      if (title.length >= self.maxFolderNameLength) {
        if (self.pasting) {
          self.showErrorAlert(self.getString('errorTitleTruncate'))
        }
      } else {
        self.hideErrorAlert()
      }
      self.currentTitle = title

      var noError = true
      if (title.length > self.maxFolderNameLength) noError = false

      return noError
    }

    onUrlEdit(event) {
      var self = this
      var url = $('#favorites-input-url').val()
      var noError = true

      if (noError && self.onTitleEdit()) self.hideErrorAlert()
    }

    hideErrorAlert() {
      var message = $('.favorites-dialog-message')
      if (!message.is(':hidden')) {
        message.slideUp(150, 'swing')
      }
      $('#favorites-input-name, #favorites-input-url').removeClass('favorites-error')
    }

    showErrorAlert(msg) {
      var message = $('.favorites-dialog-message')
      if ($('.selector-container').length) {
        message.css('margin-top', '15px')
      }
      if (message.length) {
        var icon = $('.favorites-dialog-message-icon', message)
        message.empty().append(icon, msg)
        if (message.is(':hidden')) {
          message.slideDown(150, 'swing')
        }
      } else {
        this.showNotice(msg, true)
      }
    }

    showNotice(noticeText, isError, altClass) {
      let self = this
      if (noticeText) {
        var extraClass = ''
        if (typeof altClass != 'undefined' && altClass != null) extraClass = altClass
        this.hideNotice()
        var notice = $('<div class="favorites-notice"></div>')
          .append(
            '<div class="flg"><p><span class="navicon-fav-heart ' +
            extraClass +
            '"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="26.292 -14.9 512 512"><path fill="#000" d="M282.353 459.781c-2.629 0-5.35-.815-7.618-2.448-6.441-4.628-158.637-113.196-217.683-199.182-35.555-51.79-40.635-113.557-13.424-165.166 22.948-43.537 65.305-70.566 110.655-70.566 70.566 0 110.021 43.355 128.162 71.2 18.048-27.845 57.593-71.2 128.069-71.2 45.261 0 87.708 27.029 110.565 70.566 27.118 51.609 22.13 113.286-13.425 165.166-59.046 85.985-211.152 194.554-217.592 199.182-2.358 1.63-4.989 2.448-7.709 2.448zM154.192 48.723c-35.464 0-68.933 21.678-87.345 56.507-22.494 42.811-18.049 94.329 11.882 137.956 49.16 71.562 171.878 163.807 203.624 187.116 31.836-23.31 154.374-115.554 203.533-187.116 29.932-43.627 34.375-95.236 11.882-137.956-18.321-34.83-51.79-56.507-87.254-56.507-83.444 0-114.645 74.194-115.917 77.369-3.99 9.886-20.407 9.977-24.396 0-1.271-3.085-32.473-77.369-116.009-77.369z"/></svg></span></span><span class="favorites-notice-message ">' +
            noticeText +
            '</span></p></div>'
          )
          .hide()
          .insertAfter('.favorites-tools-header')

        if (isError) {
          notice.append(
            '<a class="favorites-notice-close-button" title="close notice"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 14.85 14.85"><path fill="#000" d="M14.85 1.414L13.436 0l-6.01 6.01L1.413 0 0 1.414l6.01 6.01L0 13.437l1.415 1.414 6.01-6.01 6.01 6.01 1.415-1.414-6.01-6.01"/></svg></a>'
          )
          notice.find('p:first').prepend('<span class="favorites-notice-icon"></span>')
          notice.find('.favorites-notice-close-button').click(function (event) {
            self.hideNotice(true)
          })
        }

        notice.next().addClass('favorites-top-space')

        notice.slideDown(300, 'swing')
        if (
          !this.isAuth &&
          this.isiPad &&
          $('div.favorites-notice a.favorites-signin-link').length
        ) {
          $('div.favorites-notice').bind('click', function (e) {
            window.location = $(this)
              .find('a.favorites-signin-link')
              .attr('href')
            return false
          })
        }
      }
    }

    hideNotice(animate) {
      var notice = $('.favorites-container').find('.favorites-notice')
      if (notice.length) {
        notice.next().removeClass('favorites-top-space')

        if (animate) {
          notice.slideUp(150, 'swing', function () {
            notice.remove()
          })
        } else {
          notice.remove()
        }
      }
    }

    onKeydown(event) {
      if (event.keyCode == 8 || event.keyCode == 46) {
        self.pasting = false
      }
      if (
        event.keyCode != 8 &&
        event.keyCode != 46 &&
        event.keyCode != 13 &&
        event.keyCode != event.ctrlKey &&
        event.keyCode != event.shiftKey &&
        event.keyCode != event.altKey &&
        event.keyCode != event.metaKey
      ) {
        var title = $(event.target).val()
        if (title.length >= self.maxFolderNameLength) {
          this.showErrorAlert(this.getString('errorMaxTitleLength'))
        } else {
          this.hideErrorAlert()
        }
        self.keyStroke = true
        self.pasting = false
      }
    }

    onFavItemKeydown(event) {
      var code = event.keyCode || event.witch
      if (code == 9) {
        var elm = $(event.target)
        if (elm.is('[id^=favitem]')) {
          elm = elm.parent('div')
          elm.closest('.favorites-item').addClass('favorites-hover')
          elm.siblings().removeClass('favorites-hover')
        } else {
          elm = elm.parent('li')
          elm
            .siblings()
            .find('.favorites-item-toolbar')
            .hide()
          elm.find('.favorites-item-toolbar').show()
        }
      }
    }

    onFavItemKeyup(event) {
      var code = event.keyCode || event.witch
      if (code == 9) {
        event.stopPropagation()
        var elm = $(event.target)
        if (elm.closest('.favorites-nav-folders').length) {
          $('.favorites-item').removeClass('favorites-hover')
        } else if (elm.closest('.favorites-item').length) {
          this.nav.find('.favorites-item-toolbar').hide()
        } else {
          $('.favorites-item').removeClass('favorites-hover')
          this.nav.find('.favorites-item-toolbar').hide()
        }
      }
    }

    bindTab() {
      //   $('.favorites-container').on('keydown', '.favorites-nav-folders li,.favorites-container a, .favorites-container span', $.proxy(self.onFavItemKeydown, self));
      //   $('.favorites-container').on('keyup', '.favorites-nav-folders li,.favorites-container a, .favorites-container span', $.proxy(self.onFavItemKeyup, self));
      // TODO TypeError: can't assign to property "guid" on ".favorites-nav-folders li,.favorites-container a, .favorites-container span": not an object
    }

    onTitlePaste() {
      self.pasting = true
    }

    onTimeOut() {
      if (!self.keyStroke) {
        self.hideErrorAlert()
      } else {
        self.keyStroke = false
      }
    }

    onEditModeClick(force) {
      if (force == true) this.iPadEditMode = false
      if (this.iPadEditMode) {
        this.onEditModeClose()
      } else {
        if (
          !$('.favorites-editmode-header').length ||
          $('.favorites-editmode-header').css('display') == 'none'
        ) {
          var editmode = $(
            '<div class="favorites-editmode-header"><a class="lnid-sec2_lnk7"><h3>' +
            this.config.strings.editmodetext +
            '</h3><a class="favorites-editmode-close" title="">X</a></div>'
          )
            .hide()
            .insertAfter('.favorites-tools-header')
          editmode.next().addClass('favorites-top-space')
          editmode.slideDown(100, 'swing')
        }
        this.nav.find('li').each(function () {
          var el = $(this)
          var titleEl = el.children().first()
          var width = 95

          self.truncateElementText(titleEl, width)
        })
        $('.favorites-nav-folders .favorites-item-toolbar').show()
        $('.favorites-item').addClass('favorites-hover fvip')
        $('.favorites-editmode-header').bind('click', $.proxy(self.onEditModeClose, self))
        this.iPadEditMode = true
      }
    }

    onEditModeClose() {
      this.iPadEditMode = false
      $('.favorites-nav-folders .favorites-item-toolbar').hide()
      $('.favorites-item').removeClass('favorites-hover fvip')
      $('.favorites-container')
        .find('.favorites-editmode-header')
        .remove()
      this.updateNav()
    }
    toggleFTip() {
      $('.fTipFdr').slideToggle({
        duration: 150,
        easing: 'swing'
      })
    }
    toggleFTipItem() {
      $('.fTipItem').slideToggle({
        duration: 150,
        easing: 'swing'
      })
    }

    favTips() {
      if (favorites.isAuth && cookies.get('favfhbaol') == null) {
        cookies.set('favfhbaol', '1', 'persist') //only once

        if ($('.favorites-empty-message').val() === undefined) {
          if (!fTips.tips[0]) {
            fTips.fetch('featuretip-favorites-ftuxb-myaol', false)
          } else {
            fTips.hide('favorites getstart')
          }
        } else {
          if ($('.favorites-nav-folders li').length == 0) {
            fTips.hide('favorites gotlegacy')
          } else {
            if (!fTips.tips[0]) {
              fTips.fetch('featuretip-favorites-ftuxb-myaol', false)
            } else {
              fTips.hide('favorites getstart')
            }
          }
        }
      }
    }

    makeAccessibityCompat() {
      $('a:not([href])').each(function (index, element) {
        $(element).attr('href', '#')
      })
      $('a').keydown(function (e) {
        if (e.keyCode == 32) $(e.target).click()
      })
    }

    getLocalStorage(key) {
      try {
        return JSON.parse(window.localStorage.getItem(key))
      } catch (e) {
        return null
      }
    }

    setLocalStorage(key, value) {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }

  return favorites
})()
var AuthListenerImpl = function () { }

AuthListenerImpl.prototype.onAuthStateChanged = function (authenticated) {
  if (!authenticated && window.appWindow !== undefined) {
    window.appWindow.close()
  }
}

function FavoritesListenerImpl() { }
FavoritesListenerImpl.prototype.onFavoritesChanged = function () {
  if (isClientFavorites == true && ignoreClientSync == false) {
    location.reload()
  }
}
